import { handleActions } from "redux-actions";
import { initialUserDetailsState } from "./initialUserDetailsState";
import { UserDetailsConstants } from "./userDetailsConstants";
import {
  requestFail,
  requestPending,
  requestSuccess,
} from "../../../utils/fetch";

export const userDetailsReducer = handleActions(
  {
    [requestSuccess(UserDetailsConstants.FETCH_USER_DETAILS)]: (
      state,
      action
    ) => ({
      ...state,
      details: action.payload.user || null,
      fetchUserDetailsLoading: false,
      fetchUserDetailsFailure: false,
      fetchUserDetailsLoaded: true,
    }),
    [requestPending(UserDetailsConstants.FETCH_USER_DETAILS)]: (state) => ({
      ...state,
      fetchUserDetailsLoading: true,
      fetchUserDetailsLoaded: false,
      fetchUserDetailsFailure: false,
    }),
    [requestFail(UserDetailsConstants.FETCH_USER_DETAILS)]: (state) => ({
      ...state,
      fetchUserDetailsLoading: false,
      fetchUserDetailsLoaded: true,
      fetchUserDetailsFailure: true,
    }),
    [requestSuccess(UserDetailsConstants.CREATE_USER)]: (state) => ({
      ...state,
      createUserLoading: false,
      createUserFailure: false,
      createUserLoaded: true,
    }),
    [requestPending(UserDetailsConstants.CREATE_USER)]: (state) => ({
      ...state,
      createUserLoading: true,
      createUserFailure: false,
      createUserLoaded: false,
    }),
    [requestFail(UserDetailsConstants.CREATE_USER)]: (state) => ({
      ...state,
      createUserLoading: false,
      createUserFailure: true,
      createUserLoaded: true,
    }),
    [requestSuccess(UserDetailsConstants.UPDATE_USER_DETAILS)]: (state) => ({
      ...state,
      updateUserDetailsLoading: false,
      updateUserDetailsFailure: false,
      updateUserDetailsLoaded: true,
    }),
    [requestPending(UserDetailsConstants.UPDATE_USER_DETAILS)]: (state) => ({
      ...state,
      updateUserDetailsLoading: true,
      updateUserDetailsFailure: false,
      updateUserDetailsLoaded: false,
    }),
    [requestFail(UserDetailsConstants.UPDATE_USER_DETAILS)]: (state) => ({
      ...state,
      updateUserDetailsLoading: false,
      updateUserDetailsFailure: true,
      updateUserDetailsLoaded: true,
    }),
    [requestSuccess(UserDetailsConstants.UPDATE_USER_STATUS)]: (state) => ({
      ...state,
      updateUserStatusLoading: false,
      updateUserStatusFailure: false,
      updateUserStatusLoaded: true,
    }),
    [requestPending(UserDetailsConstants.UPDATE_USER_STATUS)]: (state) => ({
      ...state,
      updateUserStatusLoading: true,
      updateUserStatusFailure: false,
      updateUserStatusLoaded: false,
    }),
    [requestFail(UserDetailsConstants.UPDATE_USER_STATUS)]: (state) => ({
      ...state,
      updateUserStatusLoading: false,
      updateUserStatusFailure: true,
      updateUserStatusLoaded: true,
    }),
    [requestSuccess(UserDetailsConstants.UPDATE_IMAGE)]: (state) => ({
      ...state,
      updateUserImageLoading: false,
      updateUserImageFailure: false,
      updateUserImageLoaded: true,
    }),
    [requestPending(UserDetailsConstants.UPDATE_IMAGE)]: (state) => ({
      ...state,
      updateUserImageLoading: true,
      updateUserImageFailure: false,
      updateUserImageLoaded: false,
    }),
    [requestFail(UserDetailsConstants.UPDATE_IMAGE)]: (state) => ({
      ...state,
      updateUserImageLoading: false,
      updateUserImageFailure: true,
      updateUserImageLoaded: true,
    }),
    [requestSuccess(UserDetailsConstants.DELETE_IMAGE)]: (state) => ({
      ...state,
      deleteUserImageLoading: false,
      deleteUserImageFailure: false,
      deleteUserImageLoaded: true,
    }),
    [requestPending(UserDetailsConstants.DELETE_IMAGE)]: (state) => ({
      ...state,
      deleteUserImageLoading: true,
      deleteUserImageFailure: false,
      deleteUserImageLoaded: false,
    }),
    [requestFail(UserDetailsConstants.DELETE_IMAGE)]: (state) => ({
      ...state,
      deleteUserImageLoading: false,
      deleteUserImageFailure: true,
      deleteUserImageLoaded: true,
    }),
    [requestSuccess(UserDetailsConstants.GET_LOGIN_DETAILS)]: (
      state,
      action
    ) => ({
      ...state,
      details: action.payload.user || null,
      getLoginDetailsLoading: false,
      getLoginDetailsFailure: false,
      getLoginDetailsLoaded: true,
    }),
    [requestPending(UserDetailsConstants.GET_LOGIN_DETAILS)]: (state) => ({
      ...state,
      getLoginDetailsLoading: true,
      getLoginDetailsLoaded: false,
      getLoginDetailsFailure: false,
    }),
    [requestFail(UserDetailsConstants.GET_LOGIN_DETAILS)]: (state) => ({
      ...state,
      getLoginDetailsLoading: false,
      getLoginDetailsLoaded: true,
      getLoginDetailsFailure: true,
    }),

    [requestSuccess(UserDetailsConstants.USER_LOGOUT)]: (state) => ({
      ...state,
      details: null,
      logoutLoading: false,
      logoutFailure: false,
      logoutLoaded: true,
    }),
    [requestPending(UserDetailsConstants.USER_LOGOUT)]: (state) => ({
      ...state,
      logoutLoading: true,
      logoutLoaded: false,
      logoutFailure: false,
    }),
    [requestFail(UserDetailsConstants.USER_LOGOUT)]: (state) => ({
      ...state,
      logoutLoading: false,
      logoutLoaded: true,
      logoutFailure: true,
    }),
    [requestSuccess(UserDetailsConstants.REFRESH_TOKEN)]: (state) => ({
      ...state,
      details: action.payload,
      refreshTokenLoading: false,
      refreshTokenFailure: false,
      refreshTokenLoaded: true,
    }),
    [requestPending(UserDetailsConstants.REFRESH_TOKEN)]: (state) => ({
      ...state,
      refreshTokenLoading: true,
      refreshTokenLoaded: false,
      refreshTokenFailure: false,
    }),
    [requestFail(UserDetailsConstants.REFRESH_TOKEN)]: (state) => ({
      ...state,
      refreshTokenLoading: false,
      refreshTokenLoaded: true,
      refreshTokenFailure: true,
    }),
    [requestSuccess(UserDetailsConstants.RESET_PASSWORD)]: (state) => ({
      ...state,
      resetPasswordLoading: false,
      resetPasswordFailure: false,
      resetPasswordLoaded: true,
    }),
    [requestPending(UserDetailsConstants.RESET_PASSWORD)]: (state) => ({
      ...state,
      resetPasswordLoading: true,
      resetPasswordFailure: false,
      resetPasswordLoaded: false,
    }),
    [requestFail(UserDetailsConstants.RESET_PASSWORD)]: (state) => ({
      ...state,
      resetPasswordLoading: false,
      resetPasswordLoaded: true,
      resetPasswordFailure: true,
    }),

    [requestSuccess(UserDetailsConstants.RESET_LOGIN_PASSWORD)]: (state) => ({
      ...state,
      resetPasswordLoading: false,
      resetPasswordFailure: false,
      resetPasswordLoaded: true,
    }),
    [requestPending(UserDetailsConstants.RESET_LOGIN_PASSWORD)]: (state) => ({
      ...state,
      resetPasswordLoading: true,
      resetPasswordFailure: false,
      resetPasswordLoaded: false,
    }),
    [requestFail(UserDetailsConstants.RESET_LOGIN_PASSWORD)]: (state) => ({
      ...state,
      resetPasswordLoading: false,
      resetPasswordLoaded: true,
      resetPasswordFailure: true,
    }),

    [requestSuccess(UserDetailsConstants.FORGOT_PASSWORD)]: (state) => ({
      ...state,
      forgotPasswordLoading: false,
      forgotPasswordFailure: false,
      forgotPasswordLoaded: true,
    }),
    [requestPending(UserDetailsConstants.FORGOT_PASSWORD)]: (state) => ({
      ...state,
      forgotPasswordLoading: true,
      forgotPasswordFailure: false,
      forgotPasswordLoaded: false,
    }),
    [requestFail(UserDetailsConstants.FORGOT_PASSWORD)]: (state) => ({
      ...state,
      forgotPasswordLoading: false,
      forgotPasswordLoaded: true,
      forgotPasswordFailure: true,
    }),
    [requestSuccess(UserDetailsConstants.UPDATED_PASSWORD)]: (state) => ({
      ...state,
      updatePasswordLoading: false,
      updatePasswordFailure: false,
      updatePasswordLoaded: true,
    }),
    [requestPending(UserDetailsConstants.UPDATED_PASSWORD)]: (state) => ({
      ...state,
      updatePasswordLoading: true,
      updatePasswordFailure: false,
      updatePasswordLoaded: false,
    }),
    [requestFail(UserDetailsConstants.UPDATED_PASSWORD)]: (state) => ({
      ...state,
      updatePasswordLoading: false,
      updatePasswordLoaded: true,
      updatePasswordFailure: true,
    }),
  },
  initialUserDetailsState()
);
