import React from "react";
import {Button} from "antd";
import "./CustomButton.scss";

const BlueButton = props => {
    const {className, onClick, style} = props;
    return (
        <div className="customButton-blue" align="left">
            <Button style={style} className={`${className || ""} primary-color-hover fw-500`} onClick={onClick || null}>
                {props.children}
            </Button>
        </div>
    );
};

export default BlueButton;
