export const initialCommonState = () => ({
  fetchStatesLoading: false,
  fetchStatesFailure: false,
  fetchStatesLoaded: false,
  fetchProductsLoading: false,
  fetchProductsFailure: false,
  fetchProductsLoaded: false,
  fetchTimeZonesLoading: false,
  fetchTimeZonesFailure: false,
  fetchTimeZonesLoaded: false
});
