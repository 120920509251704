import React, {useEffect} from "react";
import {Form, Input, DatePicker} from "antd";

import "./Inpur.scss";

const CustomInput = props => {
    const {
        id,
        label,
        value,
        width,
        onChange,
        prefix,
        className,
        placeholder,
        name,
        type,
        error,
        maxLength,
        disabled
    } = props;
    useEffect(() => {
        let element = document.getElementsByClassName("ant-picker-input");
        if (element[0]?.children[0]?.title)
            element[0].children[0].title = ""
    }, [])
    return (
        <div style={{maxWidth: width}} className="custom-input">
            <Form.Item>
                {label && <div className="label">{label}</div>}
                {type === "password" ? (
                    <Input.Password
                        disabled={disabled}
                        className={className}
                        prefix={prefix}
                        autoComplete="new-password"
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                    />
                ) : type === "date" ? (
                    <DatePicker
                        disabled={disabled}
                        className={className}
                        prefix={prefix}
                        name={name}
                        title=""
                        value={value}
                        placeholder={placeholder}
                        onChange={e => onChange({target: {name, value: e}})}
                    />
                ) : (
                    <Input
                        disabled={disabled}
                        id={id}
                        type={type}
                        className={className}
                        prefix={prefix}
                        maxLength={maxLength}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        onChange={e => {
                            e.target.value = e.target.value === " " ? "" : e.target.value;
                            if (onChange) onChange(e);
                        }}
                    />
                )}
                <span className="text-danger">{error}</span>
            </Form.Item>
        </div>
    );
};
export default CustomInput;
