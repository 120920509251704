import { EMAIL_REGEXP } from "../constants/constants";
import { notification } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  refreshToken,
  userLogout,
} from "../redux/modules/userDetails/userDetailsActions";
import { store, history } from "../common/services/ReduxService";
export const getFromStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeFromStorage = (key) => {
  return localStorage.removeItem(key);
};

export const setIntoStorage = (info) => {
  Object.keys(info).forEach((key) => {
    localStorage.setItem(key, info[key]);
  });
};

export const fetchToken = () => {
  const onSuccess = (res) => {
    localStorage.setItem("AuthToken", res.token);
  };

  const onFail = () => {
    openNotification({
      message: "Error",
      description: "Error while processing your request!",
      error: true,
    });
  };
  store.dispatch(
    refreshToken({
      tokenId: localStorage.getItem("refreshToken") || "",
      onSuccess,
      onFail,
    })
  );
};

export const clearStorage = () => {
  localStorage.clear();
};

export const getToken = () => localStorage.getItem("AuthToken");

export const getUserInfo = () => {
  let userInfo = getFromStorage("userInfo");
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
};

export const displayErrorMessage = (err) => {
  try {
    const data = (err && err.data) || {};
    return data.message || data.error || "Something went wrong";
  } catch (e) {
    return "Something went wrong";
  }
};

export const openNotification = (notificationObj) => {
  const obj = {
    message: notificationObj?.message
      ? notificationObj?.message
      : "Notification Title",
    description: notificationObj?.description
      ? notificationObj?.description
      : // eslint-disable-next-line max-len
        "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: notificationObj?.icon ? (
      notificationObj?.icon
    ) : // eslint-disable-next-line react/react-in-jsx-scope
    !notificationObj.error ? (
      // eslint-disable-next-line react/react-in-jsx-scope
      <CheckCircleTwoTone twoToneColor="#52c41a" />
    ) : (
      // eslint-disable-next-line react/react-in-jsx-scope
      <CloseCircleTwoTone twoToneColor="#f74d4d" />
    ),
  };
  notification.open(obj);
};

export const validate = (key, value) => {
  switch (key) {
    case "email":
      if (!value) {
        return "This is required — you’ll need to enter an email.";
      } else if (!EMAIL_REGEXP.test(value)) {
        return "Are you sure that the address is typed correctly?";
      }
      return null;
    default:
      return null;
  }
};

export const emailValidation = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //eslint-disable-line
    email
  );
};

export const phoneNumberValidation = (mobile) => {
  return (
    /^\d{10}$/.test(mobile) ||
    /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/.test(mobile)
  );
};

export const passwordValidation = (password) => {
  return /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
};

export const sortArray = (a, b, keyword) => {
  let a_copy = a[keyword] ? a[keyword] : "zzzz";
  let b_copy = b[keyword] ? b[keyword] : "zzzz";
  return a_copy.localeCompare(b_copy);
};

export const numberWithCommas = (x) => {
  return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

const isNumericInputPhoneNumber = (event) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};

const isModifierKeyPhoneNumber = (event) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

export const enforceFormat = (event) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInputPhoneNumber(event) && !isModifierKeyPhoneNumber(event)) {
    event.preventDefault();
  }
};
export const formatToPhone = (event) => {
  if (isModifierKeyPhoneNumber(event)) {
    return;
  }

  // I am lazy and don't like to type things more than once
  const target = event.target;
  const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6 && input.length <= 10) {
    target.value = `(${zip}) ${middle}-${last}`;
  } else if (input.length > 3) {
    target.value = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${zip}`;
  }
};
