import { handleActions } from "redux-actions";
import { initialLocationsState } from "./initialLocationsState";
import { LocationConstants } from "./locationsConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const locationsReducer = handleActions(
  {
    [requestSuccess(LocationConstants.FETCH_LOCATIONS)]: state => ({
      ...state,
      fetchLocationsLoading: false,
      fetchLocationsFailure: false,
      fetchLocationsLoaded: true
    }),
    [requestPending(LocationConstants.FETCH_LOCATIONS)]: state => ({
      ...state,
      fetchLocationsLoading: true,
      fetchLocationsLoaded: false,
      fetchUserDetailsFailure: false
    }),
    [requestFail(LocationConstants.FETCH_LOCATIONS)]: state => ({
      ...state,
      fetchLocationsLoading: false,
      fetchLocationsLoaded: true,
      fetchLocationsFailure: true
    }),
    [requestSuccess(LocationConstants.EXPORTS_MACHINES)]: state => ({
      ...state,
      exportMachinesLoading: false,
      exportMachinesFailure: false,
      exportMachinesLoaded: true
    }),
    [requestPending(LocationConstants.EXPORTS_MACHINES)]: state => ({
      ...state,
      exportMachinesLoading: true,
      exportMachinesFailure: false,
      exportMachinesLoaded: false
    }),
    [requestFail(LocationConstants.EXPORTS_MACHINES)]: state => ({
      ...state,
      exportMachinesLoading: false,
      exportMachinesFailure: true,
      exportMachinesLoaded: true
    }),
    [requestSuccess(LocationConstants.DEACTIVATE_MACHINES)]: state => ({
      ...state,
      deactivateMachineLoading: false,
      deactivateMachineFailure: false,
      deactivateMachineLoaded: true
    }),
    [requestPending(LocationConstants.DEACTIVATE_MACHINES)]: state => ({
      ...state,
      deactivateMachineLoading: true,
      deactivateMachineFailure: false,
      deactivateMachineLoaded: false
    }),
    [requestFail(LocationConstants.DEACTIVATE_MACHINES)]: state => ({
      ...state,
      deactivateMachineLoading: false,
      deactivateMachineFailure: true,
      deactivateMachineLoaded: true
    }),
    [requestSuccess(LocationConstants.FETCH_MACHINE_BY_ID)]: state => ({
      ...state,
      fetchKioskByIdLoading: false,
      fetchKioskByIdFailure: false,
      fetchKioskByIdLoaded: true
    }),
    [requestPending(LocationConstants.FETCH_MACHINE_BY_ID)]: state => ({
      ...state,
      fetchKioskByIdLoading: true,
      fetchKioskByIdFailure: false,
      fetchKioskByIdLoaded: false
    }),
    [requestFail(LocationConstants.FETCH_MACHINE_BY_ID)]: state => ({
      ...state,
      fetchKioskByIdLoading: false,
      fetchKioskByIdFailure: true,
      fetchKioskByIdLoaded: true
    }),
    [requestSuccess(LocationConstants.FETCH_MACHINE_DASHBOARD)]: state => ({
      ...state,
      fetchKioskDashboardLoading: false,
      fetchKioskDashboardFailure: false,
      fetchKioskDashboardLoaded: true
    }),
    [requestPending(LocationConstants.FETCH_MACHINE_DASHBOARD)]: state => ({
      ...state,
      fetchKioskDashboardLoading: true,
      fetchKioskDashboardFailure: false,
      fetchKioskDashboardLoaded: false
    }),
    [requestFail(LocationConstants.FETCH_MACHINE_DASHBOARD)]: state => ({
      ...state,
      fetchKioskDashboardLoading: false,
      fetchKioskDashboardFailure: true,
      fetchKioskDashboardLoaded: true
    }),
    [requestSuccess(LocationConstants.UPDATE_GENERAL_DETAILS)]: state => ({
      ...state,
      updateGeneralDetailsLoading: false,
      updateGeneralDetailsFailure: false,
      updateGeneralDetailsLoaded: true
    }),
    [requestPending(LocationConstants.UPDATE_GENERAL_DETAILS)]: state => ({
      ...state,
      updateGeneralDetailsLoading: true,
      updateGeneralDetailsFailure: false,
      updateGeneralDetailsLoaded: false
    }),
    [requestFail(LocationConstants.UPDATE_GENERAL_DETAILS)]: state => ({
      ...state,
      updateGeneralDetailsLoading: false,
      updateGeneralDetailsFailure: true,
      updateGeneralDetailsLoaded: true
    })
  },
  initialLocationsState()
);
