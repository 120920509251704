import { createAction } from "redux-actions";
import { LocationConstants } from "./locationsConstants";

export const fetchLocations = createAction(LocationConstants.FETCH_LOCATIONS);
export const exportsMachines = createAction(LocationConstants.EXPORTS_MACHINES);
export const deactivateMachine = createAction(
  LocationConstants.DEACTIVATE_MACHINES
);

export const fetchLocationById = createAction(
  LocationConstants.FETCH_MACHINE_BY_ID
);
export const fetchKioskDashboard = createAction(
  LocationConstants.FETCH_MACHINE_DASHBOARD
);
export const updateKioskGeneralInfo = createAction(
  LocationConstants.UPDATE_GENERAL_DETAILS
);
