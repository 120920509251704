import React, { useState } from "react";
import "./../Profile.scss";
import CustomInput from "../../../common/components/CustomInput";
import BlueButton from "../../../common/components/BlueButton";
import { useDispatch } from "react-redux";
import {
  getUserInfo,
  openNotification,
  passwordValidation,
} from "../../../utils/common";
import {
  resetPassword,
  updatePassword,
} from "../../../redux/modules/userDetails/userDetailsActions";
import { useHistory, useParams, withRouter } from "react-router-dom";

const ResetPasswordCard = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    id: "",
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const [error, setError] = useState(false);

  const updatedPassword = () => {
    const onSuccess = (response) => {
      openNotification({
        message: "Success",
        description: "Password updated successfully!",
      });
      setState({});
    };
    const onFail = (res) => {
      openNotification({
        message: "Error",
        description: res?.data || "Error while processing your request!",
        error: true,
      });
    };
    const user = getUserInfo();
    state.id = window.location.pathname.includes("profile") ? user?.id : id;
    if (
      passwordValidation(state.newPassword) &&
      state.newPassword === state.confirmPassword
    ) {
      const data = { ...state };
      if (!window.location.pathname.includes("profile")) {
        delete data.oldPassword;
      }

      dispatch(
        window.location.pathname.includes("profile")
          ? resetPassword({ data: data, onSuccess, onFail })
          : updatePassword({ data: data, onSuccess, onFail })
      );
    } else {
      setError(true);
    }
  };
  const handelChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <div className="update-card">
      <div className="card-header">Reset Password</div>
      <div className="card-content">
        <div>
          {history.location.pathname === `/administrators/${id}` ? (
            ""
          ) : (
            <CustomInput
              onChange={handelChange}
              value={state.oldPassword}
              type={"password"}
              name={"oldPassword"}
              label="Current Password"
              placeholder="Enter Current Password"
              className="pl-2i"
              error={
                error && !state.oldPassword
                  ? "Enter valid Current Password"
                  : false
              }
            />
          )}
        </div>
        <div className="mt-20">
          <CustomInput
            onChange={handelChange}
            type={"password"}
            value={state.newPassword}
            name={"newPassword"}
            label="New Password"
            placeholder="Enter New Password"
            className="pl-2"
            error={
              error && !passwordValidation(state.newPassword)
                ? "Enter valid New Password"
                : false
            }
          />
        </div>
        <div className="mt-20">
          <CustomInput
            className="pl-2"
            onChange={handelChange}
            type={"password"}
            value={state.confirmPassword}
            name={"confirmPassword"}
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            error={
              error &&
              (state.newPassword !== state.confirmPassword ||
                !state.confirmPassword)
                ? state.newPassword !== state.confirmPassword
                  ? "Confirm password does not match!"
                  : "Enter valid Confirm Password"
                : false
            }
          />
        </div>
        <div align="right" className="mt-26 mb-6">
          <BlueButton
            style={{ color: "#F5F5F5" }}
            className="fw-400"
            onClick={() => updatedPassword()}
          >
            Reset
          </BlueButton>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPasswordCard);
