import React from "react";
import CustomInput from "../../../common/components/CustomInput";
import BlueButton from "../../../common/components/BlueButton";
import CustomSelect from "../../../common/components/CustomSelect";

const GeneralInfoCard = ({
  storeDetails,
  handelState,
  stateList,
  errorMsg,
  updateDetails,
  error,
}) => {
  return (
    <div className="update-card" style={{ marginLeft: "-15px" }}>
      <div className="card-header">General Info</div>
      <div className="card-content">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <CustomInput
              disabled={true}
              onChange={handelState}
              name={"chainName"}
              value={storeDetails?.chainName}
              width="347px"
              label="Chain Name"
              placeholder="Enter Chain Name"
              errorMsg={errorMsg?.chainName}
              error={
                (error && !storeDetails.chainName
                  ? "Enter valid chain name"
                  : false) || errorMsg?.chainName
              }
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomInput
              disabled={true}
              onChange={handelState}
              name={"storeName"}
              value={storeDetails?.storeName}
              width="347px"
              label="Store Name"
              placeholder="Enter Store Name"
              errorMsg={errorMsg?.storeName}
              error={
                (error && !storeDetails.storeName
                  ? "Enter valid store name"
                  : false) || errorMsg?.storeName
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-md-12 col-lg-12  col-sm-12">
            <CustomInput
              disabled={true}
              onChange={handelState}
              name={"address"}
              value={storeDetails?.address}
              width="347px"
              label="Address"
              placeholder="Enter address"
              errorMsg={errorMsg?.address}
              error={
                (error && !storeDetails.address
                  ? "Enter valid address"
                  : false) || errorMsg?.address
              }
            />
          </div>
        </div>
        <div className="row mt-20">
          <div className="col-md-4 col-sm-12">
            <CustomInput
              disabled={true}
              onChange={handelState}
              name={"city"}
              value={storeDetails?.city}
              width="220px"
              label="City"
              placeholder="Enter city"
              errorMsg={errorMsg?.city}
              error={
                (error && !storeDetails.city ? "Enter valid city" : false) ||
                errorMsg?.city
              }
            />
          </div>
          <div className="col-md-4 col-sm-12">
            {/*<div className="label">State</div>*/}
            <CustomSelect
              disabled={true}
              labelKey="stateName"
              label="State"
              name="stateId"
              width={"224px"}
              value={storeDetails?.stateId}
              placeholder="Select"
              onChange={handelState}
              options={stateList}
              error={
                (error && !storeDetails.stateId ? "Select state" : false) ||
                errorMsg?.state
              }
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomInput
              disabled={true}
              max={5}
              onChange={handelState}
              name={"zip"}
              value={storeDetails?.zip}
              width="220px"
              errorMsg={errorMsg?.Zip}
              maxLength={5}
              label="Zip Code"
              placeholder="Enter Zip"
              error={
                (error && !storeDetails.zip ? "Enter valid zip" : false) ||
                errorMsg?.Zip
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoCard;
