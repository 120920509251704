import React from "react";
import ChartCard from "../../../common/components/ChartCard";
import moment from "moment";
import { numberWithCommas } from "../../../utils/common";

const ChartCardsDiv = ({ kioskDetails, fromDashboard }) => {
  return (
    <div className="row chart-card" align="center">
      {fromDashboard ? (
        <>
          <div
            align={"center"}
            className="mb-20 col-md-6 ctm-col-lg-2 col-lg-2 col-sm-12"
          >
            <ChartCard
              digit={numberWithCommas(kioskDetails?.labelsPrintedToday)}
              label="Labels Printed Today"
              fillColor="#0E4C8C"
            />
          </div>
          <div
            align={"center"}
            className="mb-20 col-md-6 ctm-col-lg-2 col-lg-2 col-sm-12"
          >
            <ChartCard
              digit={numberWithCommas(kioskDetails?.labelsPrintedMonth)}
              label="Labels Printed This Month"
              fillColor="#AD9863"
            />
          </div>
          <div
            align={"center"}
            className="mb-20 col-md-6 ctm-col-lg-2 col-lg-2 col-sm-12"
          >
            <ChartCard
              digit={numberWithCommas(kioskDetails?.onlineKiosks)}
              label="Kiosks Online"
              fillColor="#0E8C50"
            />
          </div>
          <div
            align={"center"}
            className="mb-20 col-md-6 ctm-col-lg-2 col-lg-2 col-sm-12"
          >
            <ChartCard
              digit={numberWithCommas(kioskDetails?.offlineKiosks)}
              label="Kiosks Offline"
              fillColor="#545454"
            />
          </div>
          <div
            align={"center"}
            className="mb-20 col-md-6 ctm-col-lg-2 col-lg-2 col-sm-12"
          >
            <ChartCard
              digit={numberWithCommas(kioskDetails?.storeLocations)}
              label="Store Locations"
              fillColor="#003366"
            />
          </div>
        </>
      ) : (
        <>
          <div className="mb-20 col-md-6 col-lg-3 col-sm-12">
            <ChartCard
              digit={kioskDetails?.labelsPrintedToday}
              label="Labels Printed Today"
              fillColor="#0E4C8C"
            />
          </div>
          <div className="mb-20 col-md-6 col-lg-3 col-sm-12">
            <ChartCard
              digit={kioskDetails?.labelsPrintedMonth}
              label="Labels Printed This Month"
              fillColor="#AD9863"
            />
          </div>
          <div className="mb-20 col-md-6 col-lg-3 col-sm-12">
            <ChartCard
              digit={kioskDetails?.totalPrinted}
              label="Total Labels Printed"
              fillColor="#0E8C50"
            />
          </div>
          <div className="mb-20 col-md-6 col-lg-3 col-sm-12">
            <ChartCard
              digit={
                kioskDetails?.lastPrintedDate
                  ? moment(kioskDetails?.lastPrintedDate).format("MM/DD/YYYY")
                  : "-"
              }
              label="Last Printed Date"
              fillColor="#084380"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChartCardsDiv;
