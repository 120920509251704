import React from "react";
import { Card } from "antd";
import AmCharts from "@amcharts/amcharts3-react";
import {DataPickerInput} from "../../../common/components/DataPickerInput";
import moment from "moment";

const BarChart = ({
  statisticsList,
  storeList,
  fromDashboard,
  getStatisticList,
  fromLocation,
}) => {
  const config = {
    type: "serial",
    theme: "light",
    marginRight: 20,
    dataProvider: statisticsList,
    valueAxes: [
      {
        axisAlpha: 0,
        position: "left",
        //   "title": "Visitors from month"
      }, // eslint-disable-line
    ],
    startDuration: 1,
    graphs: [
      {
        balloonText: "<b>[[category]]: [[value]]</b>",
        fillColorsField: "color",
        fillAlphas: 0.9,
        lineAlpha: 0.2,
        type: "column",
        valueField: "count",
      }, // eslint-disable-line
    ],
    chartCursor: {
      categoryBalloonEnabled: false,
      cursorAlpha: 0,
      zoomable: false,
    },
    categoryField: "month",
    categoryAxis: {
      gridPosition: "start",
      labelRotation: 45,
    },
    // "export": {
    //   "enabled": true
    // }
  };

  return (
    <div>
      {/* <Row>
                <Col lg={12} md={12} sm={24} xs={24}> */}
      <Card
        title="Statistics"
        className="card fw-500"
        extra={
          <div className=" d-flex justify-content-end">
            <div className="mt-8 mr-19">Filter By Year</div>
            <DataPickerInput defaultValue={moment()} placeholder="Filter By Year" size="small" width="220px" picker="year"
                             handleChange={(e) => getStatisticList(e)}/>
            <div
              style={{
                backgroundColor: fromLocation ? "#0E4C8C" : "#0E4C8C",
                height: "8px",
                minWidth: "27px",
                maxWidth: "27px",
                marginLeft: "50px",
              }}
              className="mr-10 ml-30 mt-14"
            />
            <div className="mt-8">Entries</div>
          </div>
        }
      >
        <AmCharts.React
          style={{ width: "100%", height: "524px" }}
          options={config}
        />
      </Card>
      {/* </Col>
            </Row> */}
    </div>
  );
};

export default BarChart;
