import React from "react";
import "./SearchBar.scss";
import { Button } from "antd";
import CustomInput from "../CustomInput";
import SearchIcon from "../../../assets/icon/SearchIcon.svg";

const SearchBar = props => {
  const { className, name, onChange, onSearch } = props;
  return (
    <div className={`search-bar ${className}`} align="left">
      <div className="button-div">
        <CustomInput
          onChange={onChange}
          name={name}
          placeholder="Search..."
          width="338px"
        />
        <Button
          onClick={onSearch || null}
          icon={<img alt="" src={SearchIcon} height="22px" />}
        />
      </div>
    </div>
  );
};

export default SearchBar;
