import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { openNotification } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../common/components/Header";
import Settings from "./components/Settiings";
import Issues from "./components/Issues";
import Overview from "./components/Overview";
import {
  fetchKioskDashboard,
  fetchLocationById,
} from "../../redux/modules/Locations/locationsActions";
import Loading from "../../common/components/Loading";
import {
  fetchStores,
  fetchStoresById,
} from "../../redux/modules/Stores/storesActions";
import { fetchAuditLog } from "../../redux/modules/AuditLog/auditLogActions";
import {
  fetchEntries,
  fetchStatistics,
} from "../../redux/modules/Entries/entriesActions";
import { fetchStates } from "../../redux/modules/Common/commonActions";
import MachineEntries from "./components/MachineEntries";
import {config} from "../../utils/config";
import "./LocationDetails.scss";

const LocationDetails = (props) => {
  const [userInfo, setUserInfo] = useState({}); //eslint-disable-line
  const [auditList, setAuditList] = useState([]);
  const [issueData, setIssueData] = useState({});
  const [statisticsList, setStatisticsList] = useState([]);
  const [entriesList, setEntriesList] = useState([]); //eslint-disable-line
  const [currentTab, setCurrentTab] = useState(
    props.location.search === "?tab=1" ? 1 : 0
  );
  const [storeList, setStoreList] = useState([]);
  const [kioskDashboardData, setKioskDashboardData] = useState(null);
  const [kioskDetails, setKioskDetails] = useState({});
  const [machineDetails, setMachineDetails] = useState({});
  const [storeDetails, setStoreDetails] = useState({});
  const [connectivityDetails, setConnectivityDetails] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [states, setStates] = useState([]); // eslint-disable-line
  const [reqBodyEntries, setReqBodyEntries] = useState({
    keyword: "",
    gender: null,
    productNames: [],
    sortBy: "",
    kioskId: props.match.params?.id,
    machineName: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageIndex: 0,
    isAscending: false,
    timeZoneName: global?.timeZoneName || config.TIMEZONE,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    getStoreList();
    getLocationById();
    getMachineDashboard();
    getAuditList(
      {
        pageSize: 6,
        pageIndex: 0,
        isAscending: true,
        timeZoneName: global?.timeZoneName || config.TIMEZONE,
      },
      1
    );
    getStatisticList();
    getStateList();
  }, []);
  useEffect(() => {
    getEntriesList(reqBodyEntries);
  }, [reqBodyEntries]);
  const getStoreById = (id) => {
    //eslint-disable-line
    const onSuccess = (response) => {
      setStoreDetails(response);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(
      fetchStoresById({
        data: { id },
        onSuccess,
        onFail,
      })
    );
  };
  const getStoreList = () => {
    const onSuccess = (response) => {
      _.forEach(response, (item) => {
        item.id = item.storeId;
        item.name = item.storeName;
      });
      setStoreList(response || []);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(fetchStores({ onSuccess, onFail }));
  };
  const getLocationById = () => {
    //eslint-disable-line
    const onSuccess = (response) => {
      setKioskDetails(response);
      setMachineDetails(response);
      setStoreDetails(response);
      setConnectivityDetails(response);
      getStoreById(response?.storeId);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(
      fetchLocationById({
        data: { id: props.match.params?.id },
        onSuccess,
        onFail,
      })
    );
  };
  const getMachineDashboard = () => {
    //eslint-disable-line
    const onSuccess = (response) => {
      setKioskDashboardData(response);
      // setKioskDetails(response);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(
      fetchKioskDashboard({
        data: {
          id: props.match.params?.id,
          timeZoneName: reqBodyEntries?.timeZoneName,
        },
        onSuccess,
        onFail,
      })
    );
  };
  const getEntriesList = (reqBody) => {
    //eslint-disable-line
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      setTotalRecords(res?.totalRecords || 0);
      _.forEach(res?.results, (item) => {
        item.key = item.id;
      });
      setEntriesList(res?.results);
    };
    dispatch(
      fetchEntries({
        data: reqBody,
        onSuccess,
        onFail,
      })
    );
  };
  const getAuditList = (data, index) => {
    //eslint-disable-line
    const onSuccess = (response) => {
      _.forEach(response?.results, (item) => {
        item.key = item.id;
      });
      if (index === 1) {
        setAuditList(response?.results || []);
      } else setIssueData(response);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    data.kioskId = props.match.params?.id;
    data.isAscending = false;
    dispatch(
      fetchAuditLog({
        data,
        onSuccess,
        onFail,
      })
    );
  };
  const getStatisticList = (yearValue) => {
    //eslint-disable-line
    const defaultYear = moment().years();
    const onSuccess = (response) => {
      _.forEach(response, (item) => {
        item.color = "#0e4c8c";
        item.month = item.month.toUpperCase();
      });
      setStatisticsList(response || []);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const data = {Year: moment(yearValue).years() || defaultYear, KioskId: 0};
    dispatch(
      fetchStatistics({
        data: data,
        onSuccess,
        onFail,
      })
    );
  };
  const getStateList = () => {
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setStates(res);
    };
    dispatch(
      fetchStates({
        onSuccess,
        onFail,
      })
    );
  };
  const loading = useSelector(
    (state) =>
      state.location.fetchKioskByIdLoading ||
      state.location.fetchKioskDashboardLoading ||
      state.entries.fetchStatisticsLoading ||
      state.auditLog.fetchAuditLogLoading ||
      state.common.fetchStatesLoading ||
      state.store.updateStoreDetailsLoading ||
      state.store.fetchStoresLoading
  );
  const tabs = [
    "Overview",
    "Entries",
    "Issues",
    "Settings", //eslint-disable-line
  ];
  return (
    <>
      {loading && <Loading />}
      <Header>
        {kioskDetails?.storeName || storeDetails?.storeName || "-"}
      </Header>
      <div className="details">
        <div className="blue-div-image">
          <div className="blue-div-on-image">
            <div className="row">
              <div className="row col-lg-6 col-md-12 details-div">
                <div className="col-sm-6 mt-12 col-md-3 col-xs-6 col-lg-4">
                  <div>Kiosk ID</div>
                  <div className="mt-12">
                    Kiosk{kioskDetails?.kioskId || storeDetails?.kioskId}
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mt-12 col-lg-4">
                  <div>
                    {kioskDetails?.address || storeDetails?.address || "-"}
                  </div>
                  <div className="mt-12">{`${kioskDetails?.city ||
                    storeDetails?.city ||
                    "-"}, ${kioskDetails?.stateCode ||
                    "- "} ${kioskDetails.zip ||
                    storeDetails?.zip ||
                    "-"}`}</div>
                </div>
              </div>
              <div align="right" className="col-lg-6 col-md-12">
                {/*for no issue "RGBA(74, 167, 101, 0.25)" , border #4AA765*/}
                <button
                  style={{
                    backgroundColor: "rgba(245, 166, 35, 0.25)",
                    borderColor: " #f5a623",
                  }}
                  className="need-button mr-5"
                >
                  Needs Review
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mt-30 mb-35">
                {tabs.map((item, index) => (
                  <span
                    key={index}
                    onClick={() => setCurrentTab(index)}
                    className={`${
                      currentTab === index ? "overview-underline" : ""
                    } ${index > 0 && "ml-3"} cursor-pointer`}
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative" style={{ position: "relative" }}>
          <div className="update-div row" style={{ paddingBottom: "75px" }}>
            {currentTab === 0 && (
              <Overview
                storeList={storeList}
                fromLocation
                getStatisticList={getStatisticList}
                statisticsList={statisticsList}
                auditList={auditList}
                kioskDetails={kioskDashboardData}
                fromDashboard={false}
                setCurrentTab={setCurrentTab}
              />
            )}
            {currentTab === 1 && (
              <MachineEntries
                totalRecords={totalRecords}
                entriesList={entriesList}
                setReqBody={setReqBodyEntries}
                reqBody={reqBodyEntries}
              />
            )}
            {currentTab === 2 && (
              <Issues getAuditList={getAuditList} issueData={issueData} />
            )}
            {currentTab === 3 && (
              <Settings
                kioskId={props.match.params?.id}
                getLocationById={getLocationById}
                getStoreById={getStoreById}
                states={states}
                storeDetails={storeDetails}
                connectivityDetails={connectivityDetails}
                kioskDetails={kioskDetails}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationDetails;
