import React, { useEffect, useState } from "react";
import "./../Profile.scss";
import UploadIcon from "../../../assets/icon/Upload.svg";
import DeleteIcon from "../../../assets/icon/Delete.svg";
import UserIcon from "../../../assets/images/user.png";
import { ButtonWithIcon } from "../../../common/components/ButtonWithIcon";
import CustomInput from "../../../common/components/CustomInput";
import BlueButton from "../../../common/components/BlueButton";
import {
  emailValidation,
  enforceFormat,
  formatToPhone,
  getUserInfo,
  openNotification,
  phoneNumberValidation,
  setIntoStorage,
} from "../../../utils/common";
import {
  deleteUserImage,
  updateUserDetails,
  updateUserImage,
} from "../../../redux/modules/userDetails/userDetailsActions";
import { useDispatch } from "react-redux";
import { config } from "../../../utils/config";
import AvatarIcon from "../../../common/components/AvatarIcon";
import { useHistory, useParams } from "react-router-dom";
import CustomSelect from "../../../common/components/CustomSelect";
import { fetchTimeZones } from "../../../redux/modules/Common/commonActions";

const GeneralInfoCard = ({ userInfo, isAdministrator, getUser }) => {
  const { id } = useParams();

  const ColorStyle = {
    color: "#545454",
    lineHeight: "20px",
    marginBottom: "11px",
    fontWeight: "400",
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [error, setError] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  useEffect(() => {
    getTimeZones();
  }, []);
  useEffect(() => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      id,
      timeZoneName,
      avatar,
    } = userInfo;
    setState({
      firstName,
      lastName,
      phoneNumber,
      email,
      id,
      avatar,
      timeZoneName,
    });
  }, [userInfo]);
  const handelChange = (e) => {
    let { name, value } = e.target;
    if (name === "phoneNumber" && value.length > 14) {
      value = value.substring(0, 14);
    }
    setState({ ...state, [name]: value });
  };
  const updateUser = () => {
    const onSuccess = () => {
      getUser();
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (state?.timeZoneName && history?.location?.pathname?.includes('profile')) {
        global.timeZoneName = state?.timeZoneName;
        localStorage.setItem("userInfo", JSON.stringify({...userInfo, timeZoneName: state?.timeZoneName}));
      }
      openNotification({
        message: "Success",
        description: "User details updated successfully!",
      });
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    if (
        state.firstName &&
        state.lastName &&
        phoneNumberValidation(state.phoneNumber) &&
        emailValidation(state.email)
    ) {
      dispatch(updateUserDetails({data: state, onSuccess, onFail}));
    } else {
      setError(true);
    }
  };

  const getTimeZones = () => {
    //eslint-disable-line
    const onFail = (res) => {
      openNotification({
        message: "Error",
        description:
          res?.date?.message || "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setTimeZoneList(res);
    };
    dispatch(
      fetchTimeZones({
        data: {},
        onSuccess,
        onFail,
      })
    );
  };

  const deleteImage = () => {
    const onSuccess = (response) => {
      if (!isAdministrator) {
        let x = getUserInfo();
        x.avatar = response.avatar;
        setIntoStorage({ userInfo: JSON.stringify(x) });
      }
      getUser();
      openNotification({
        message: "Success",
        description: "Image removed successfully!",
      });
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(
      deleteUserImage({ data: { id: userInfo?.id }, onSuccess, onFail })
    );
  };
  const updateImage = (e) => {
    // eslint-disable-line
    const formData = new FormData();
    formData.append("Id", userInfo?.id);
    if (e.target.files && e.target.files[0]) {
      formData.append("ProfileImage", e.target.files[0]);
      const onSuccess = (response) => {
        if (!isAdministrator) {
          let x = getUserInfo();
          x.avatar = response.avatar;
          setIntoStorage({ userInfo: JSON.stringify(x) });
        }
        getUser();
        openNotification({
          message: "Success",
          description: "User details updated successfully!",
        });
      };
      const onFail = () => {
        openNotification({
          message: "Error",
          description: "Error while processing your request!",
          error: true,
        });
      };
      dispatch(updateUserImage({ data: formData, onSuccess, onFail }));
    }
  };
  useEffect(() => {
    const inputElement = document.getElementById("phoneNumberProfile");
    inputElement.addEventListener("keydown", enforceFormat);
    inputElement.addEventListener("keyup", formatToPhone);
  }, []);
  return (
    <div className="update-card">
      <div className="card-header">General Info</div>
      <div className="card-content">
        <div className="image-div">
          <AvatarIcon
            size={70}
            src={config.IMAGE_URL + userInfo?.avatar}
            className="profile-image2 fs-20 "
            name={`${userInfo?.firstName ? userInfo?.firstName[0] : ""}${
              userInfo?.lastName ? userInfo?.lastName[0] : ""
            }`}
          />
          <ButtonWithIcon
            onClick={() => {
              document.getElementById("profileImage").click();
            }}
            className="upload-button mt-19"
            icon={UploadIcon}
          >
            Upload Picture
          </ButtonWithIcon>
          <input type="file" onChange={updateImage} id="profileImage" hidden />
          <ButtonWithIcon
            onClick={deleteImage}
            className="mt-19"
            icon={DeleteIcon}
          >
            Remove
          </ButtonWithIcon>
        </div>
        <div className="row mt-14">
          <div className="col-md-6 col-sm-12">
            <CustomInput
              onChange={handelChange}
              value={state?.firstName}
              name="firstName"
              width="347px"
              label="First Name"
              placeholder="Enter First Name"
              error={
                error && !state?.firstName ? "Enter valid First Name" : false
              }
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomInput
              onChange={handelChange}
              value={state?.lastName}
              name="lastName"
              width="347px"
              label="Last Name"
              placeholder="Enter Last Name"
              error={
                error && !state?.lastName ? "Enter valid Last Name" : false
              }
            />
          </div>
        </div>
        <div className="row mt-20">
          <div className="col-md-6 col-sm-12">
            <CustomInput
              onChange={handelChange}
              id={"phoneNumberProfile"}
              value={state?.phoneNumber}
              name="phoneNumber"
              width="347px"
              label="Phone Number"
              placeholder="Enter Phone Number"
              error={
                error && !phoneNumberValidation(state?.phoneNumber)
                  ? "Enter valid Phone Number"
                  : false
              }
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomInput
              onChange={handelChange}
              value={state?.email}
              name="email"
              width="347px"
              label="Email"
              placeholder="Enter Email"
              error={
                error && !emailValidation(state?.email)
                  ? "Enter valid Email"
                  : false
              }
            />
          </div>
        </div>

          <div className="row mt-20">
            <div className="col-lg-6 col-md-6 col-xs-12 mt-20">
              <CustomSelect
                name="timeZoneName"
                width="347px"
                showSearch
                labelKey={'displayName'}
                label="Time Zone"
                ColorStyle={ColorStyle}
                onChange={handelChange}
                placeholder="Select"
                options={timeZoneList}
                value={state?.timeZoneName}
                error={
                  error && !state?.timeZoneName
                    ? "Please select TimeZone"
                    : false
                }
              />
            </div>
          </div>
        <div align="right" className="mt-33 mb-6">
          <BlueButton
            style={{ color: "#F5F5F5" }}
            className="fw-400"
            onClick={updateUser}
          >
            Save Changes
          </BlueButton>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoCard;
