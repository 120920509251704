const keyMirror = require("keymirror");

export const UserDetailsConstants = keyMirror({
  FETCH_USER_DETAILS: undefined,
  CREATE_USER: undefined,
  UPDATE_USER_DETAILS: undefined,
  UPDATE_USER_STATUS: undefined,
  GET_LOGIN_DETAILS: undefined,
  USER_LOGOUT: undefined,
  REFRESH_TOKEN: undefined,
  FORGOT_PASSWORD: undefined,
  RESET_PASSWORD: undefined,
  RESET_LOGIN_PASSWORD: undefined,
  DELETE_IMAGE: undefined,
  UPDATE_IMAGE: undefined,
  UPDATED_PASSWORD: undefined,
});
