import { createAction } from "redux-actions";
import { StoresConstants } from "./storesConstants";

export const fetchStores = createAction(StoresConstants.FETCH_STORES);
export const fetchStoresById = createAction(StoresConstants.FETCH_STORE_BY_ID);
export const fetchStoreDashboard = createAction(
  StoresConstants.FETCH_STORE_DASHBOARD
);
export const updateStoreGeneralInfo = createAction(
  StoresConstants.UPDATE_STORE_DETAILS
);
