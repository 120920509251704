import React from 'react';

const monitorSvg = ({ icon_color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1024 1024'
      x='0px'
      y='0px'
    >
      <path
        d='M960,106.7H64c-34.1,0-64,29.9-64,64v554.7c0,34.1,29.9,64,64,64h362.7v85.3h-128c-12.8,0-21.3,8.5-21.3,21.3
	s8.5,21.3,21.3,21.3h426.7c12.8,0,21.3-8.5,21.3-21.3s-8.5-21.3-21.3-21.3h-128v-85.3H960c34.1,0,64-29.9,64-64V170.7
	C1024,136.5,994.1,106.7,960,106.7z M554.7,874.7h-85.3v-85.3h85.3V874.7z M981.3,725.3c0,12.8-8.5,21.3-21.3,21.3H64
	c-12.8,0-21.3-8.5-21.3-21.3V170.7c0-12.8,8.5-21.3,21.3-21.3h896c12.8,0,21.3,8.5,21.3,21.3V725.3z M917.3,192H106.7
	c-12.8,0-21.3,8.5-21.3,21.3v469.3c0,12.8,8.5,21.3,21.3,21.3h810.7c12.8,0,21.3-8.5,21.3-21.3V213.3
	C938.7,200.5,930.1,192,917.3,192z M896,661.3H128V234.7h768V661.3z'
        fill={icon_color}
      />
    </svg>
  );
};

export default monitorSvg;
