import { handleActions } from "redux-actions";
import { initialDashboardState } from "./initialDashboardState";
import { DashboardConstants } from "./dashboardConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const dashboardReducer = handleActions(
  {
    [requestSuccess(DashboardConstants.FETCH_DASHBOARD)]: state => ({
      ...state,
      fetchDashboardLoading: false,
      fetchDashboardFailure: false,
      fetchDashboardLoaded: true
    }),
    [requestPending(DashboardConstants.FETCH_DASHBOARD)]: state => ({
      ...state,
      fetchDashboardLoading: true,
      fetchDashboardLoaded: false,
      fetchDashboardFailure: false
    }),
    [requestFail(DashboardConstants.FETCH_DASHBOARD)]: state => ({
      ...state,
      fetchDashboardLoading: false,
      fetchDashboardLoaded: true,
      fetchDashboardFailure: true
    })
  },
  initialDashboardState()
);
