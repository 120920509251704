import React from "react";
import {Table, Empty} from "antd";
import "./CustomTable.scss";

const CustomTable = props => {
    // let locale = {
    //     emptyText: "no entries are available currently"
    // };
    const {
        columns,
        loading,
        className,
        dataSource,
        onChange,
        pagination,
        locale
    } = props;
    return (
        <div className={`custom-table ${className}`}>
            <Table
                locale={{emptyText: locale || <Empty description={'no entries are available currently'}/>}}
                rowClassName="table-style"
                className="table-header-row"
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                showSorterTooltip={false}
                onChange={onChange}
                pagination={pagination ? {...pagination, showTitle: false} : pagination}
            />
        </div>
    );
};
export default CustomTable;
