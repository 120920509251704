import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { UserDetailsConstants } from "./userDetailsConstants";

function* getUserDetails(action) {
  yield call(
    request({
      type: UserDetailsConstants.FETCH_USER_DETAILS,
      method: "GET",
      url: `User/${action.payload?.data?.id}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* createUser(action) {
  yield call(
    request({
      type: UserDetailsConstants.CREATE_USER,
      method: "POST",
      url: `User`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* updateUserDetails(action) {
  yield call(
    request({
      type: UserDetailsConstants.UPDATE_USER_DETAILS,
      method: "PUT",
      url: `User/${action.payload?.data?.id}`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* updateUserStatus(action) {
  yield call(
    request({
      type: UserDetailsConstants.UPDATE_USER_STATUS,
      method: "POST",
      url: `User/ChangeUserStatus`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}

function* loginDetails(action) {
  yield call(
    request({
      type: UserDetailsConstants.GET_LOGIN_DETAILS,
      method: "POST",
      url: `Login/login`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}

function* userLogout(action) {
  yield call(
    request({
      type: UserDetailsConstants.USER_LOGOUT,
      method: "POST",
      url: `Login/logout`,
      // headers: {
      //     "Content-Type": "application/json",
      //     "Authorization":`${action.payload.tokenId}`
      // },
      data: action.payload,
    }),
    action
  );
}
function* refreshToken(action) {
  yield call(
    request({
      type: UserDetailsConstants.REFRESH_TOKEN,
      method: "POST",
      url: `Login/refresh-token`,
      data: action.payload,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
  yield delay(500)
}

function* changePassword(action) {
  yield call(
    request({
      type: UserDetailsConstants.RESET_PASSWORD,
      method: "POST",
      url: `User/ChangePassword`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* updatePassword(action) {
  yield call(
    request({
      type: UserDetailsConstants.UPDATED_PASSWORD,
      method: "POST",
      url: `User/UpdatePassword`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* resetLoginPassword(action) {
  yield call(
    request({
      type: UserDetailsConstants.RESET_PASSWORD,
      method: "POST",
      url: `Login/updatepassword`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}

function* forgotPassword(action) {
  yield call(
    request({
      type: UserDetailsConstants.FORGOT_PASSWORD,
      method: "POST",
      url: `Login/forgetpassword?email=${action.payload.data.email || ""}`,
      data: {},
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* removeImage(action) {
  yield call(
    request({
      type: UserDetailsConstants.DELETE_IMAGE,
      method: "POST",
      url: `User/DeletePicture?id=${action.payload.data?.id}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}

function* updateImage(action) {
  yield call(
    request({
      type: UserDetailsConstants.UPDATE_IMAGE,
      method: "POST",
      url: `User/ChangePicture`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(UserDetailsConstants.FETCH_USER_DETAILS, getUserDetails);
  yield takeLatest(UserDetailsConstants.UPDATE_USER_DETAILS, updateUserDetails);
  yield takeLatest(UserDetailsConstants.UPDATE_USER_STATUS, updateUserStatus);
  yield takeLatest(UserDetailsConstants.CREATE_USER, createUser);
  yield takeLatest(UserDetailsConstants.DELETE_IMAGE, removeImage);
  yield takeLatest(UserDetailsConstants.UPDATE_IMAGE, updateImage);
  yield takeLatest(UserDetailsConstants.GET_LOGIN_DETAILS, loginDetails);
  yield takeLatest(UserDetailsConstants.USER_LOGOUT, userLogout);
  yield takeLatest(UserDetailsConstants.REFRESH_TOKEN, refreshToken);
  yield takeLatest(UserDetailsConstants.RESET_PASSWORD, changePassword);
  yield takeLatest(UserDetailsConstants.UPDATED_PASSWORD, updatePassword);
  yield takeLatest(
    UserDetailsConstants.RESET_LOGIN_PASSWORD,
    resetLoginPassword
  );
  yield takeLatest(UserDetailsConstants.FORGOT_PASSWORD, forgotPassword);
}
