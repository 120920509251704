import React from "react";
import "./SetPassword.scss";
import CustomInput from "../../common/components/CustomInput";
import BlueButton from "../../common/components/BlueButton";
import SignInBox from "../../common/components/SignInBox";

const SetPassword = () => {
  return (
    <SignInBox className="set-password">
      <div className="set-password-title" align="left">
        Set Password
      </div>
      <div>
        <div className="set-password-description">
          Create your password below.
        </div>
        <CustomInput
          type="password"
          className="sign-in-input mt-20 forgot-input fw-300"
          placeholder="Password"
          prefix={
            <img
              width="12px"
              src="https://i.ibb.co/hx0TgpZ/passIcon.png"
              alt=""
              style={{ marginRight: "12px" }}
            />
          }
        />
        <CustomInput
          type="password"
          className="sign-in-input mt-20 forgot-input fw-300"
          placeholder="Confirm password"
          prefix={
            <img
              width="12px"
              src="https://i.ibb.co/hx0TgpZ/passIcon.png"
              alt=""
              style={{ marginRight: "12px" }}
            />
          }
        />{" "}
      </div>
      <div className="mt-20" align="left">
        <BlueButton>Register</BlueButton>
      </div>
    </SignInBox>
  );
};

export default SetPassword;
