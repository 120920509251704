import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { EntriesConstants } from "./entriesConstants";

function* getEntries(action) {
  yield call(
    request({
      type: EntriesConstants.FETCH_ENTRIES,
      method: "POST",
      url: `KioskEntry/SearchKioskEntries`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}
function* exportsEntries(action) {
  yield call(
    request({
      type: EntriesConstants.EXPORTS_ENTRIES,
      method: "POST",
      url: `KioskEntry/ExportKioskEntries`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}
function* getEntriesStatistics(action) {
  yield call(
    request({
      type: EntriesConstants.FETCH_STATISTICS,
      method: "POST",
      url: "KioskEntry/GetStatisticsByDates",
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}
export default function* rootSaga() {
  yield takeLatest(EntriesConstants.FETCH_ENTRIES, getEntries);
  yield takeLatest(EntriesConstants.EXPORTS_ENTRIES, exportsEntries);
  yield takeLatest(EntriesConstants.FETCH_STATISTICS, getEntriesStatistics);
}
