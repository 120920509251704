import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { AccountConstants } from "./accountConstants";

function* getAccounts(action) {
  yield call(
    request({
      type: AccountConstants.FETCH_ACCOUNTS,
      method: "POST",
      url: `User/SearchUser`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* getRoles(action) {
  // const {
  //     searchText,
  //     city,
  //     state,
  //     store,
  //     date,
  //     product,
  //     dmaZip,
  //     pageSize,
  //     pageIndex
  // } = action.payload.data;
  yield call(
    request({
      type: AccountConstants.FETCH_ROLES,
      method: "GET",
      url: `Role`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}
export default function* rootSaga() {
  yield takeLatest(AccountConstants.FETCH_ACCOUNTS, getAccounts);
  yield takeLatest(AccountConstants.FETCH_ROLES, getRoles);
}
