import SignIn from "../pages/SignIn";
import ForgetPassword from "../pages/ForgetPassword";
import SetPassword from "../pages/SetPassword";
import ResetPassword from "../pages/ResetPassword";
import Dashboard from "../pages/Dashboard";
import Locations from "../pages/Locations";
import Profile from "../pages/Profile";
import Accounts from "../pages/Accounts";
import Entries from "../pages/Entries";
import LocationDetails from "../pages/LocationDetails";
// import KiosksProfile from "../pages/Locations/components/KiosksProfile";
import AdministarationProfile from "../pages/Accounts/components/AdministarationProfile";

export const routePaths = {
  signIn: "/",
  forgotPass: "/forgot-password",
  setPass: "/set-password",
  resetPass: "/reset-password/:token",
  dashboard: "/dashboard",
  locations: "/locations",
  profile: "/profile",
  account: "/administrators",
  accountById: "/administrators/:id",
  entries: "/entries",
  details: "/locations/locationProfile/:id",
  KiosksProfile: "/kiosksprofile",
  AdministrationProfile: "/administrationProfile",
};

export const authRoutes = [
         {
           title: "Dashboard",
           path: "/",
           url: "/",
           accessBy: "Both",
           exact: true,
           main: Dashboard,
         },
         {
           title: "Dashboard",
           path: routePaths.dashboard,
           url: routePaths.dashboard,
           exact: true,
           main: Dashboard,
         },
         {
           title: "Kiosks",
           path: routePaths.locations,
           url: routePaths.locations,
           exact: true,
           main: Locations,
         },
         //  {
         //    title: "Locations",
         //    path: routePaths.locations,
         //    url: routePaths.locations,
         //    exact: true,
         //    main: LocationDetails,
         //  },
         {
           title: "Kiosks",
           path: routePaths.details,
           url: routePaths.details,
           exact: true,
           main: LocationDetails,
         },
         {
           title: "Profile",
           path: routePaths.profile,
           url: routePaths.profile,
           exact: true,
           main: Profile,
         },
         {
           title: "Account",
           path: routePaths.accountById,
           url: routePaths.accountById,
           exact: true,
           main: Profile,
         },
         {
           title: "Accounts",
           path: routePaths.account,
           url: routePaths.account,
           exact: true,
           main: Accounts,
         },
         {
           title: "AdministrationProfile",
           path: routePaths.AdministrationProfile,
           url: routePaths.AdministrationProfile,
           exact: true,
           main: AdministarationProfile,
         },
         {
           title: "Entries",
           path: routePaths.entries,
           url: routePaths.entries,
           exact: true,
           main: Entries,
         },
         {
           title: "Not Found",
           path: "*",
           url: "*",
           accessBy: "Both",
           exact: true,
           main: Dashboard,
         }, // eslint-disable-line
       ];

export const unAuthRoutes = [
  {
    title: "Wheelhouse Vending",
    path: routePaths.signIn,
    url: routePaths.signIn,
    exact: true,
    main: SignIn,
  },
  {
    title: "Forgot Password",
    path: routePaths.forgotPass,
    url: routePaths.forgotPass,
    exact: true,
    main: ForgetPassword,
  },
  {
    title: "Set Password",
    path: routePaths.setPass,
    url: routePaths.setPass,
    exact: true,
    main: SetPassword,
  },
  {
    title: "Reset Password",
    path: routePaths.resetPass,
    url: routePaths.resetPass,
    exact: true,
    main: ResetPassword,
  },
  {
    title: "Not Found",
    path: "*",
    url: "*",
    exact: true,
    main: SignIn,
  }, // eslint-disable-line
];
