import React from "react";
import {useHistory} from "react-router-dom";
import LoginImage from "../../../assets/images/Category_SQUARES.jpg";
import Logo from '../../../assets/images/logo.svg';
import "./SignInBox.scss";

const SignInBox = props => {
    const history = useHistory();
    return (
        <div align="center" className={`sign-in-box ${props.className}`}>
            <div className="logo-div cursor-pointer">
                <img
                    onClick={() => history.push("/")}
                    className="h-100 w-100"
                    src={Logo}
                    alt="logo"
                />
            </div>
            <div className="login-card row">
                <div className="col-lg-6 col-md-12 image-div-login p-0">
                    <img alt={"login image"} src={LoginImage}/>
                </div>
                <div className="col-md-6 col-sm-12 form-div" align="center">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default SignInBox;
