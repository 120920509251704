import React, {useEffect, useState} from "react";
import "./Profile.scss";
import {displayErrorMessage, formatPhoneNumber, getUserInfo} from "../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserDetails} from "../../redux/modules/userDetails/userDetailsActions";
import moment from "moment";
import GeneralInfoCard from "./components/GeneralInfoCard";
import ResetPasswordCard from "./components/ResetPasswordCard";
import Header from "../../common/components/Header";
import Loading from "../../common/components/Loading";

const Profile = ({match}) => {
    const [userInfo, setUserInfo] = useState({});
    const dispatch = useDispatch();
    const isAdministrator = match.path === '/administrators/:id';

    useEffect(() => {
        if (match.path === "/accounts/:id") {
            getUser();
        } else getUser();
    }, []);

    const getUser = () => {
        const onSuccess = response => {
            setUserInfo(response);
        };
        const onFail = () => {
            displayErrorMessage("Error while fetching vendors.");
        };
        const user = getUserInfo();
        dispatch(
            fetchUserDetails({
                data: {id: match?.params?.id ? match.params.id : user?.id},
                onSuccess,
                onFail
            })
        );
    };
    const loading = useSelector(
        state =>
            state.userDetails.fetchUserDetailsLoading ||
            state.userDetails.updateUserDetailsLoading ||
            state.userDetails.updateUserImageLoading ||
            state.userDetails.deleteUserImageLoading ||
            state.userDetails.resetPasswordLoading
    );

    return (
      <>
        {loading && <Loading />}
        <Header>
          {userInfo?.firstName
            ? userInfo?.firstName + " " + userInfo?.lastName
            : ""}
        </Header>
        <div className="profile">
          <div className="blue-div fw-300">
            <div className="row details-div">
              <div className="col-sm-6 col-md-3 mt-12 col-lg-4">
                {userInfo?.userName || userInfo?.normalizedUserName}
                <div className="mt-2">
                  {formatPhoneNumber(userInfo?.phoneNumber)}
                </div>
              </div>
              <div className="col-sm-6 col-md-3 mt-12 col-lg-4">
                Last Login:{" "}
                {userInfo?.lastLoginDateTime
                  ? moment(userInfo.lastLoginDateTime).format(
                      "MM/DD/YYYY - hh:mm A"
                    )
                  : "Never Logged In"}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mt-30 mb-35">
                <span className="overview-underline fw-300">Overview</span>
              </div>
            </div>
          </div>
          <div className="position-relative" style={{ position: "relative" }}>
            <div className="update-div row">
              <div className="col-lg-6 col-md-12 mb-20">
                <GeneralInfoCard
                  isAdministrator={isAdministrator}
                  getUser={getUser}
                  userInfo={userInfo}
                />
              </div>
              <div className="col-lg-6 col-md-12 reset-password-div">
                <ResetPasswordCard />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Profile;
