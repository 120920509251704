import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {matchPath} from "react-router";
import {useHistory} from "react-router-dom";
import "./Sidebar.scss";
// import logo from "../../../assets/logo/admin-logo.svg";
import Logout from "../../../assets/icon/Logout.svg";
import DashboardIcon from "../../../assets/icon/sidebar/dashboard.svg";
import EntriesIcon from "../../../assets/icon/sidebar/entries.svg";
import AccountsIcon from "../../../assets/icon/sidebar/administrators.svg";
// import KiosksIcon from "../../../assets/icon/sidebar/kiosks.svg";
import KiosksIcon from "../../../assets/icon/sidebar/locations.svg";
import DownArrow from "../../../assets/icon/DownArrow.svg";
import ExpandSidebar from "../../../assets/icon/ExpandSidebarIcon.svg";
import UserIcon from '../../../assets/images/user.png';
import UnfoldSidebar from '../../../assets/icon/MenuUnfold.svg';
import Logo from '../../../assets/images/logo.png'
import AvatarIcon from '../AvatarIcon';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarContent
} from "react-pro-sidebar";
import {userLogout} from "../../../redux/modules/userDetails/userDetailsActions";
import {routePaths} from "../../../routes";
import {
    clearStorage,
    getFromStorage,
    getUserInfo
} from "../../../utils/common";
import ProfileMenu from "./components/ProfileMenu";
import {config} from "../../../utils/config";

const Sidebar = props => {
    const {userLogout} = props;
    const [active, setActive] = useState(1);
    const imageChangedLoaded = useSelector(state => state.userDetails.updateUserImageLoaded);
    const imageChangedLoading = useSelector(state => state.userDetails.updateUserImageLoading);
    const imageRemoveLoading = useSelector(state => state.userDetails.deleteUserImageLoaded);
    const [collapsed, setCollapsed] = useState(true);
    const [userInfo, setUserInfo] = useState(getUserInfo());
    let history = useHistory();

    const onLogout = () => {
        const tokenId = getFromStorage("refreshToken");
        userLogout({tokenId});
        clearStorage();
        history.push(routePaths.signIn);
    };
    useEffect(() => {
        setActive(sideBarMenu.findIndex(i => checkActive(i.path)) + 1);
    }, [history.location.pathname]);

    useEffect(() => {
        setUserInfo(getUserInfo());
    }, [imageChangedLoaded, imageChangedLoading, imageRemoveLoading]);
    const sideBarMenu = [
      { icon: DashboardIcon, title: "Dashboard", path: routePaths.dashboard },
      { icon: EntriesIcon, title: "Entries", path: routePaths.entries },
      { icon: KiosksIcon, title: "Kiosks", path: routePaths.locations },
      { icon: AccountsIcon, title: "Administrators", path: routePaths.account },
      { icon: Logout, title: "Logout", onClick: onLogout }, //eslint-disable-line
    ];

    const checkActive = path => {
        return (
            matchPath(history.location.pathname, path)?.isExact ||
            path === matchPath(history.location.pathname, path)?.path
        );
    };
    return (
      <ProSidebar collapsed={collapsed}>
        <SidebarHeader>
          <div
            className="expand-div cursor-pointer"
            onClick={() => setCollapsed(!collapsed)}
          >
            <img
              src={collapsed ? UnfoldSidebar : ExpandSidebar}
              alt=""
              className="expandIcon"
            />
          </div>
          {!collapsed && (
            <img
              onClick={() => history.push("/")}
              src={Logo}
              alt={""}
              className="logo"
            />
          )}
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem
              className={`${checkActive(routePaths.profile) &&
                "pro-menu-item-active"}  profile-tab-div`}
              onClick={() => {
                setActive(5);
                history.push("/profile");
              }}
              icon={
                <AvatarIcon
                  color={checkActive(routePaths.profile) && "#f6d094"}
                  src={config.IMAGE_URL + userInfo?.avatar}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = UserIcon;
                  }}
                  name={`${userInfo?.firstName ? userInfo?.firstName[0] : ""}${
                    userInfo?.lastName ? userInfo?.lastName[0] : ""
                  }`}
                  className="profile-image"
                  alt=""
                />
              }
            >
              <div className="profile-div">
                <ProfileMenu onLogout={onLogout}>
                  <div onClick={(e) => e.stopPropagation()}>
                    <div className="right-arrow" align="right">
                      <img src={DownArrow} alt="" />
                    </div>
                  </div>
                </ProfileMenu>
                <div className="label">{userInfo?.fullName || "User"}</div>
                {/*<div className="sub-label">{userInfo?.roles || "undefined"}</div>*/}
              </div>
            </MenuItem>
            <div align="center" className="divider-div">
              <hr className="divider" />
            </div>
            {sideBarMenu.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  className={
                    item.path && checkActive(item?.path) && active === index + 1
                      ? "pro-menu-item-active"
                      : ""
                  }
                  onClick={() => {
                    setActive(index + 1);
                    item.path ? history.push(item.path) : item.onClick();
                  }}
                  icon={<img src={item.icon} alt="" />}
                >
                  {item.title}
                </MenuItem>
              );
            })}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    );
};

const mapStateToProps = state => {
    return {
        logoutLoading: state.userDetails.logoutLoading
    };
};
const mapDispatchToProps = dispatch => {
    return {
        userLogout: payload => dispatch(userLogout(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
