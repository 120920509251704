import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "antd";
import "./Modal.scss";
import BlueButton from "../BlueButton";
import Loading from "../Loading";

const CustomModal = props => {
    const {
        title,
        visible,
        onClose,
        className,
        children,
        btnname,
        // backBtnName,
        loading,
        onClickSubmit
    } = props;
    return (
        <Modal
            centered
            show={visible}
            onHide={() => {
                !loading && onClose();
            }}
            className={`custom-modal ${className || ""}`}
        >
            {loading && <Loading/>}
            {/*<div style={{ width: "650px" }}>*/}
            {title && <div className="md-header">{title}</div>}
            <div className="md-content">{children}</div>
            <div className="md-footer d-flex justify-content-end" align="right">
                <Button className="cancel-btn" onClick={onClose}>
                    Cancel
                </Button>
                <BlueButton className="fw-300" onClick={onClickSubmit}>{`${
                    btnname ? btnname : "Submit"
                }`}</BlueButton>
            </div>
            {/*</div>*/}
        </Modal>
    );
};

export default CustomModal;
