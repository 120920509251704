export const initialLocationsState = () => ({
  fetchLocationsLoading: false,
  fetchLocationsFailure: false,
  fetchLocationsLoaded: false,
  exportMachinesLoading: false,
  exportMachinesFailure: false,
  exportMachinesLoaded: false,
  deactivateMachineLoading: false,
  deactivateMachineFailure: false,
  deactivateMachineLoaded: false,
  fetchKioskByIdLoading: false,
  fetchKioskByIdFailure: false,
  fetchKioskByIdLoaded: false,
  fetchKioskDashboardLoading: false,
  fetchKioskDashboardFailure: false,
  fetchKioskDashboardLoaded: false,
  updateGeneralDetailsLoading: false,
  updateGeneralDetailsFailure: false,
  updateGeneralDetailsLoaded: false
});
