import React, {useState} from 'react';
import {Avatar} from 'antd';
import './Avtar.scss';

const AvatarIcon = ({src, name, size, className, color}) => {
    const [error, setError] = useState(false);
    return (
        <div className={`${size === 70 ? 'avatar70' : 'avatar'} "avatar-bg"`}>
            <Avatar shape={'round'}
                    style={{border: error && `1px solid ${color ? color : "white"}`, color, fontSize: '12px'}}
                    onError={() => setError(true)} src={src} size={size || 49}
                    className={`bg-primary-hover-color ${className}`}>{name}</Avatar>
        </div>
    );
};
export default AvatarIcon;
