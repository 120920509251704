import React, { useEffect, useState } from "react";
import GeneralInfoCard from "./GeneralInfoCard";
import NotificationCard from "./NotificationCard";
import {
  emailValidation,
  openNotification,
  phoneNumberValidation
} from "../../../utils/common";
import { updateStoreGeneralInfo } from "../../../redux/modules/Stores/storesActions";
import { useDispatch } from "react-redux";
import Connectivity from "./Connectivity";

const Settings = ({
  kioskId,
  getLocationById,
  storeDetails,
  kioskDetails,
  states,
  getStoreById,
  connectivityDetails,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [commonState, setCommonState] = useState({});
  const [error, setError] = useState(0);
  const [errorMsg, setErrorMsg] = useState({});
  useEffect(() => {
    const {
      chainName,
      storeName,
      stateId,
      chainId,
      chain,
      address,
      zip,
      city,
      emailNotification,
      smsnotification,
      kioskUrl,
      id,
    } = kioskDetails || {};
    const {
      jailbreakPassword,
      teamViewerId,
      teamViewerPassword,
      storeId,
    } = connectivityDetails;
    setState({
      storeId: id || storeId,
      storeName,
      stateId,
      chainId,
      chainName,
      address,
      zip,
      city,
      kioskUrl,
      emailNotification,
      smsnotification,
      teamViewerId,
      teamViewerPassword,
      jailbreakPassword,
      kioskId,
    });
    setCommonState({
      storeId,
      storeName,
      stateId,
      chainId,
      chainName: chain?.chainName || state?.chainName,
      address,
      zip,
      city,
      emailNotification,
      smsnotification,
      teamViewerId,
      teamViewerPassword,
      jailbreakPassword,
      kioskId,
    });
  }, [storeDetails, connectivityDetails]);
  const updateDetails = (index) => {
    //eslint-disable-line
    const onSuccess = () => {
      getStoreById(state?.storeId);
      getLocationById();
      setErrorMsg({});
      openNotification({
        message: "Success",
        description: "Machine details updated successfully!",
      });
    };
    const onFail = (error) => {
      setErrorMsg(error?.data?.errors);
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    if (
      (index === 1 &&
        state?.chainName &&
        state?.storeName &&
        state?.address &&
        state?.city &&
        state?.stateId &&
        state?.zip) ||
      (index === 2 &&
        emailValidation(state?.emailNotification) &&
        phoneNumberValidation(state?.smsnotification)) ||
      (index === 3 &&
        state?.jailbreakPassword &&
        state?.teamViewerId &&
        state?.teamViewerPassword)
    ) {
      const data1 = {
        chainName: state?.chainName,
        storeName: state?.storeName,
        address: state?.address,
        city: state?.city,
        stateId: state?.stateId,
        zip: state?.zip,
      };
      const data2 = {
        emailNotification: state?.emailNotification,
        smsnotification: state?.smsnotification,
      };
      const data3 = {
        jailbreakPassword: state?.jailbreakPassword,
        teamViewerId: state?.teamViewerId,
        teamViewerPassword: state?.teamViewerPassword,
      };
      let data = {};
      if (index === 1) data = { ...commonState, ...data1 };
      if (index === 2) data = { ...commonState, ...data2 };
      if (index === 3) data = { ...commonState, ...data3 };
      dispatch(
        updateStoreGeneralInfo({
          data,
          onSuccess,
          onFail,
        })
      );
    } else setError(index);
  };
  const handelState = (e) => {
    const { name, value } = e.target || e;
    setState({ ...state, [name]: value });
  };
  return (
    <>
      <div className="col-lg-6 col-md-12 mb-20">
        <GeneralInfoCard
          error={error === 1}
          updateDetails={updateDetails}
          errorMsg={errorMsg}
          handelState={handelState}
          stateList={states}
          storeDetails={state}
        />
      </div>
      {/* <div className="col-lg-4 col-md-12 mb-20">
        <NotificationCard
          error={error === 2}
          updateDetails={updateDetails}
          errorMsg={errorMsg}
          handelState={handelState}
          storeDetails={state}
        />
      </div> */}
      <div className="col-lg-6 col-md-12">
        <Connectivity
          error={error === 3}
          updateDetails={updateDetails}
          errorMsg={errorMsg}
          handelState={handelState}
          storeDetails={state}
        />
      </div>
    </>
  );
};

export default Settings;
