import React from "react";
import CustomTable from "../../../common/components/CustomTable";
import moment from "moment";

const StatusModal = (props) => {
  const {
    statusModalData,
    monitor,
    isKioskOnline,
    printer,
    ink,
    roll,
    issues,
  } = props; // eslint-disable-line
  // const data = [statusModalData];
  let columns;
  if (printer) {
    columns = [
      {
        title: "Printer Name",
        dataIndex: "printerName",
      },
      {
        title: "Printer Status",
        dataIndex: "printerStatusDescription",
      },
      {
        title: "Printer Issue",
        dataIndex: "printerErrorDescription",
      },
    ];
  }

  if (ink) {
    columns = [
      {
        title: "Printer Name",
        dataIndex: "printerName",
      },
      {
        title: "Ink Status",
        dataIndex: "inkStatusDescription",
      },
    ];
  }

  if (roll) {
    columns = [
      {
        title: "Printer Name",
        dataIndex: "printerName",
      },
      {
        title: "Roll Status",
        dataIndex: "labelRollStatusDescription",
      },
    ];
  }

  let data;
  if (issues) {
    data = [statusModalData];
  } else {
    data = statusModalData.auditLogs;
  }

  return (
    <>
      {/*<CustomModal*/}
      {/*  title={"Printer Status"}*/}
      {/*  visible={statusModalData}*/}
      {/*  onClose={onCancel}*/}
      {/*>*/}
      <div className="row" align="center">
        {/*<div className="col-12 mt-10">{dataModal?.storeName}</div>*/}
        {/*<div className="col-12 mt-10">{dataModal?.kioskUrl}</div>*/}
        <div className="col-12 mt-10">
          {monitor ? (
            <span>
              Kiosk is <b>{isKioskOnline ? "Online" : "Offline"}</b>
            </span>
          ) : (
            <CustomTable
              pagination={false}
              columns={columns}
              dataSource={data}
            />
          )}
        </div>
      </div>
      {/*</CustomModal>*/}
    </>
  );
};

export default StatusModal;
