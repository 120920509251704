import React, {useState} from "react";
import {connect, useSelector} from "react-redux";
import {userLogin} from "../../redux/modules/userDetails/userDetailsActions";
import {routePaths} from "../../routes";
import CheckBox from "../../common/components/CheckBox";
import CustomInput from "../../common/components/CustomInput";
import BlueButton from "../../common/components/BlueButton";
import SignInBox from "../../common/components/SignInBox";
import {openNotification, setIntoStorage, validate} from "../../utils/common";
import Loading from "../../common/components/Loading";
import Cookies from 'universal-cookie';
import "./signIn.scss";

const SignIn = props => {
    const {userLogin, history} = props;
    const cookies = new Cookies();
    const [error, setErrorMsg] = useState("");
    const [rememberMe, setRememberMe] = useState(!!cookies.get('userName'));
    const [details, setLoginDetails] = useState({
        email: cookies.get('userName') || '',
        password: '',
    });

    const onLogin = () => {
        const errMsg = validate("email", details.email);
        if (errMsg) return setErrorMsg(errMsg);

        if (rememberMe)
            cookies.set('userName', details.email);
        else
            cookies.remove('userName');

        const onFail = err => {
            console.log(err);
            setErrorMsg(err?.data);
            openNotification({
                message: "Error",
                description: "Error while processing your request!",
                error: true
            });
        };
        const onSuccess = res => {
            setIntoStorage({
                AuthToken: res.token,
                refreshToken: res.refreshTokenId,
                email: res.userName,
                roles: res.roles,
                userInfo: JSON.stringify(res || {})
            });
            history.push(routePaths.dashboard);
        };
        userLogin({
            data: details,
            onSuccess,
            onFail
        });
    };

    const onChange = e => {
        const {name, value} = e.target;
        setLoginDetails({
            ...details,
            [name]: value
        });
    };
    const loading = useSelector(
        state => state.userDetails.getLoginDetailsLoading
    );
    return (
      <div>
        {loading && <Loading noMarginLeft />}
        <SignInBox className="sign-in">
          <div className="singin-title fw-600" align="left">
            Sign In
          </div>
          <div>
            <CustomInput
              className="sign-in-input forgot-input fw-300"
              placeholder="Enter email"
              value={details.email || ""}
              name="email"
              prefix={
                <img
                  width="11px"
                  height="12px"
                  src="https://i.ibb.co/42yLFQq/userIcon.png"
                  alt=""
                />
              }
              onChange={onChange}
            />
            <CustomInput
              type="password"
              className="sign-in-input mt-20 forgot-input fw-300"
              placeholder="Password"
              value={details.password || ""}
              name="password"
              prefix={
                <img
                  width="14px"
                  height="14px"
                  src="https://i.ibb.co/hx0TgpZ/passIcon.png"
                  alt=""
                />
              }
              onChange={onChange}
            />
          </div>
          <small className="text-left text-danger">{error || ""}</small>
          <div className="mt-22 d-flex justify-content-between align-items-center">
            <CheckBox
              checked={rememberMe}
              onChange={(e) => {
                setRememberMe(e.target.checked);
              }}
              label="Remember me"
              labelClass="fw-400"
            />
            <span
              onClick={() => props.history.push("/forgot-password")}
              className="fs-14  primary-color-hover fw-500 cursor-pointer forgot-password"
            >
              Forgot password?
            </span>
          </div>
          <div className="mt-19" align="left">
            <BlueButton onClick={onLogin}>
              <span style={{ color: "white" }}>Sign In</span>
            </BlueButton>
          </div>
        </SignInBox>
      </div>
    );
};

const mapStateToProps = state => {
    return {
        detailsLoading: state.userDetails.getLoginDetailsLoading
    };
};
const mapDispatchToProps = dispatch => {
    return {
        userLogin: payload => dispatch(userLogin(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
