import React, { useState } from "react";
import "./ResetPassword.scss";
import CustomInput from "../../common/components/CustomInput";
import BlueButton from "../../common/components/BlueButton";
import SignInBox from "../../common/components/SignInBox";
import { useHistory } from "react-router-dom";
import {
  displayErrorMessage,
  openNotification,
  passwordValidation
} from "../../utils/common";
import { resetLoginPassword } from "../../redux/modules/userDetails/userDetailsActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/components/Loading";

const ResetPassword = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setErrorMsg] = useState("");
  const [details, setLoginDetails] = useState({
    newPassword: "",
    confirmPassword: ""
  });
  const setPassword = () => {
    const passwordTest = passwordValidation(details.newPassword);
    const onSuccess = () => {
      openNotification({
        message: "Success",
        description: "Password reset successfully!"
      });
      history.push("/");
    };
    const onFail = err => {
      setErrorMsg(displayErrorMessage(err));
      openNotification({
        message: "Error",
        description:
          err?.data?.message || "Error while processing your request!",
        error: true
      });
    };
    if (passwordTest && details.confirmPassword === details.newPassword)
      dispatch(
        resetLoginPassword({
          data: { ...details, token: match?.params?.token },
          onSuccess,
          onFail
        })
      );
    else if (!details.newPassword) setErrorMsg("Please enter password");
    else if (!passwordTest)
      setErrorMsg(
            "Password should contain at least 8 letter, 1 lower case letter, 1 upper case letter, 1 numeric character and 1 special character"//eslint-disable-line
      );
    else if (details.confirmPassword !== details.newPassword)
      setErrorMsg("Confirm password does not match");
  };
  const onChange = e => {
    const { name, value } = e.target;
    setLoginDetails({
      ...details,
      [name]: value
    });
  };
  const loading = useSelector(state => state.userDetails.resetPasswordLoading);
  return (
    <div>
      {loading && <Loading noMarginLeft />}
      <SignInBox className="reset-password">
        <div className="reset-password-title fw-600" align="left">
          Reset Password
        </div>
        <div>
          <div className="reset-password-description">
            Enter your new password below.
          </div>
          <CustomInput
            type="password"
            className="sign-in-input mt-20 forgot-input fw-300"
            placeholder="New password"
            name={"newPassword"}
            onChange={onChange}
            prefix={
              <img
                width="12px"
                src="https://i.ibb.co/hx0TgpZ/passIcon.png"
                alt=""
              />
            }
          />
          <CustomInput
            type="password"
            className="sign-in-input mt-20 forgot-input fw-300"
            placeholder="Repeat new password"
            name={"confirmPassword"}
            onChange={onChange}
            prefix={
              <img
                width="12px"
                src="https://i.ibb.co/hx0TgpZ/passIcon.png"
                alt=""
              />
            }
          />{" "}
        </div>
        <small className="text-left text-danger">{error || ""}</small>
        <div className="mt-20" align="left">
          <BlueButton onClick={setPassword}>Reset Password</BlueButton>
        </div>
      </SignInBox>
    </div>
  );
};

export default ResetPassword;
