import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  deactivateMachine,
  exportsMachines,
  fetchLocations,
} from "../../redux/modules/Locations/locationsActions";
import { useHistory } from "react-router-dom";
import ExportButton from "../../common/components/ExportButton";
import SearchBar from "../../common/components/SearchBar";
import CustomInput from "../../common/components/CustomInput";
import CustomSelect from "../../common/components/CustomSelect";
import CustomTable from "../../common/components/CustomTable";
import { fetchStates } from "../../redux/modules/Common/commonActions";
import Header from "../../common/components/Header";
import ActionMenu from "../../common/components/ActionMenu";
import moment from "moment";
import _ from "lodash";
import exportCSVFile from "../../utils/JSON2csv";
import { openNotification } from "../../utils/common";
import Loading from "../../common/components/Loading";
import { config } from "../../utils/config";
import { Tooltip } from "antd";
import StatusModal from "./components/StatusModal";
import Monitor from "../../common/components/svg_icons/monitor";
import Printer from "../../common/components/svg_icons/printer";
import Ink from "../../common/components/svg_icons/ink";
import Roll from "../../common/components/svg_icons/paper_roll";
import "./Locations.scss";

function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a style={{ color: "#01b2aa" }}>Previous</a>;
  }
  if (type === "next") {
    return <a style={{ color: "#01b2aa" }}>Next</a>;
  }
  return originalElement;
}

const Locations = (props) => {
  const { getLocations } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [reqBody, setReqBody] = useState({
    keyword: "",
    city: "",
    state: "",
    lastUpdatedOn: "",
    dmaZip: "",
    sortBy: "",
    stateIds: [],
    pageSize: 10,
    pageIndex: 0,
    isAscending: true,
    timeZoneName: global?.timeZoneName || config?.TIMEZONE,
  });
  const [data, setData] = useState([]); // eslint-disable-line
  const [totalRecords, setTotalRecords] = useState(0); // eslint-disable-line
  const [states, setStates] = useState([]); // eslint-disable-line

  const renderStatusData = (data, obj) => {
    return (
      <div
        align="center"
        className="statusIconsmain justify-content-center"
        style={{}}
      >
        <div className="statusIcons">
          <Tooltip
            title={
              <StatusModal
                isKioskOnline={obj?.isOnline}
                statusModalData={data}
                monitor={true}
                issues={true}
              />
            }
            color={obj.isOnline ? `green` : "red"}
          >
            <div>
              <Monitor
                icon_color={obj.isOnline ? `green` : "rgb(255, 86, 48)"}
              />
            </div>
          </Tooltip>
        </div>
        <div className="statusIcons">
          <Tooltip
            overlayInnerStyle={{ width: "360px" }}
            title={<StatusModal statusModalData={data} printer={true} />}
            color={
              data.printerStatusColorCode ||
              (data.printerStatusCode === 0 ||
              data.printerStatusCode === 16 ||
              data.printerStatusCode === 64 ||
              data.printerStatusCode === 128 ||
              (data.printerErrorCode > -1 &&
                data.printerErrorCode !== 24 &&
                data.printerErrorCode !== 27)
                ? `red`
                : data.printerStatusCode === 2 || data.printerStatusCode === 32
                ? `warning`
                : `green`)
            }
          >
            <div>
              <Printer
                icon_color={
                  data.printerStatusColorCode ||
                  (data.printerStatusCode === 0 ||
                  data.printerStatusCode === 16 ||
                  data.printerStatusCode === 64 ||
                  data.printerStatusCode === 128 ||
                  (data.printerErrorCode > -1 &&
                    data.printerErrorCode !== 24 &&
                    data.printerErrorCode !== 27)
                    ? `red`
                    : data.printerStatusCode === 2 ||
                      data.printerStatusCode === 32
                    ? `warning`
                    : `green`)
                }
              />
            </div>
          </Tooltip>
        </div>
        <div className="statusIcons">
          <Tooltip
            title={<StatusModal statusModalData={data} ink={true} />}
            color={
              data?.inkStatusColorCode ||
              (data.inkStatusCode === 0 || data.inkStatusCode === 4
                ? `red`
                : data.inkStatusCode === 2
                ? `sky blue`
                : data.inkStatusCode === 3
                ? `warning`
                : `green`)
            }
          >
            <div>
              <Ink
                icon_color={
                  data?.inkStatusColorCode ||
                  (data.inkStatusCode === 0 || data.inkStatusCode === 4
                    ? `red`
                    : data.inkStatusCode === 2
                    ? `sky blue`
                    : data.inkStatusCode === 3
                    ? `warning`
                    : `green`)
                }
              />
            </div>
          </Tooltip>
        </div>
        <div className="statusIcons">
          <Tooltip
            title={<StatusModal statusModalData={data} roll={true} />}
            color={
              data.labelRollStatusColorCode ||
              (data.labelRollStatusCode === 0 || data.labelRollStatusCode === 1
                ? `red`
                : data.labelRollStatusCode === 4
                ? `sky blue`
                : data.labelRollStatusCode === 3
                ? `warning`
                : `green`)
            }
          >
            <div>
              <Roll
                icon_color={
                  data.labelRollStatusColorCode ||
                  (data.labelRollStatusCode === 0 ||
                  data.labelRollStatusCode === 1
                    ? `red`
                    : data.labelRollStatusCode === 4
                    ? `sky blue`
                    : data.labelRollStatusCode === 3
                    ? `warning`
                    : `green`)
                }
              />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getLocationList();
  }, [reqBody]);

  useEffect(() => {
    getStateList();
  }, []);

  const locationColumns = [
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
      render: (text, data) => (
        <span
          className="cursor-pointer fw-500"
          onClick={() => {
            history.push(`/locations/locationProfile/${data?.kioskId}`);
          }}
          style={{ color: "#0e4c8c" }}
        >
          {text}
        </span>
      ),
      sorter: () => {},
    },
    {
      title: "Kiosk ID",
      dataIndex: "kioskId",
      key: "kioskId",
      sorter: () => {},
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: () => {},
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorter: () => {},
    },
    {
      title: "State",
      dataIndex: "stateName",
      key: "statename",
      render: (text, data) => data?.stateName,
      sorter: () => {},
    },
    {
      title: "Zip Code",
      dataIndex: "zip",
      key: "zip",
      sorter: () => {},
    },
    {
      title: "Printed Today",
      dataIndex: "labelsPrintedToday",
      key: "labelsPrintedToday",
      sorter: () => {},
    },
    {
      title: "Status",
      dataIndex: "kioskCurrentStatus",
      key: "kioskCurrentStatus",
      render: (text, data) => renderStatusData(text, data),
      sorter: () => {},
    },
    {
      title: "Issue",
      dataIndex: `kioskCurrentStatus`,
      key: "kioskCurrentStatus",
      render: (text) => (
        <span>
          {text?.issueDescription ? text?.issueDescription : "No Issue"}
        </span>
      ),
      sorter: () => {},
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdatedOn",
      key: "lastUpdatedOn",
      render: (text) => moment(text).format("MM/DD/YYYY - hh:mm A"),
      sorter: () => {},
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, data) => (
        <ActionMenu
          menuOptions={[
            {
              label: "View",
              to: `/locations/locationProfile/${data?.kioskId}`,
            },
            {
              label: "Entries",
              to: `/locations/locationProfile/${data?.kioskId}?tab=1`,
            }, //eslint-disable-line
            // {
            //   label: "Deactivate",
            //   onClick: () => deactivateMachineById(data?.id)
            // }//eslint-disable-line
          ]}
        />
      ),
    }, // eslint-disable-line
  ];
  const handleChange = (pagination, filters, sorter) => {
    // console.log(pagination);
    if (
      reqBody.pageIndex !== pagination.current - 1 ||
      reqBody.sortBy !== sorter.field ||
      reqBody.isAscending !== (sorter.order === "ascend") ||
      sorter.order === undefined
    )
      setReqBody({
        ...reqBody,
        pageIndex:
          pagination.current >= 0 ? pagination.current - 1 : reqBody.pageIndex,
        sortBy: sorter.order === undefined ? "" : sorter.field,
        isAscending: sorter.order === "ascend",
      });
  };

  const handleSearchInputs = (event) => {
    setReqBody({ ...reqBody, [event.target.name]: event.target.value });
  };

  const handleStateChange = (e) => {
    setReqBody({
      ...reqBody,
      stateIds: e.target.value === undefined ? [] : [e.target.value],
    });
  };

  const handlePageSize = (e) => {
    // eslint-disable-line
    setReqBody({ ...reqBody, pageSize: e.target.value, pageIndex: 0 });
  };

  const handleOnChange = (e) => {
    setReqBody({ ...reqBody, keyword: e.target.value });
  };

  // const handleDmaInputs = (event) => {
  //   //eslint-disable-line
  //   setReqBody({ ...reqBody, [event.target.name]: event.target.value });
  // };

  const handleCity = (e) => {
    setReqBody({ ...reqBody, city: e.target.value });
  };

  const handleZip = (e) => {
    setReqBody({ ...reqBody, dmaZip: e.target.value });
  };

  const ItemsOnPage = () => {
    return (
      <CustomSelect
        width="73px"
        notAllowClear
        value={reqBody.pageSize}
        onChange={handlePageSize}
        options={[10, 20, 30]}
      />
    );
  };

  const exportsMachinesList = () => {
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      console.log(
        "res?.results",
        res?.results[0]
      );
      //eslint-disable-line
      if (res?.results?.length) {
        const finalArray = res?.results?.map((i) => ({
          StoreName: i?.storeName || "-",
          KioskId: i?.kioskId || "-",
          Address: i?.address || "-",
          City: i?.city || "-",
          State: i?.stateName || "-",
          zip: i?.zip || "-",
          PrintedToday: i?.labelsPrintedToday,
          Issue: i?.kioskCurrentStatus?.issueDescription || "-",
          Date: i?.lastUpdatedOn || "-",
        }));
        exportCSVFile(finalArray, "Kiosk");
      }
    };
    dispatch(
      exportsMachines({
        data: reqBody,
        onSuccess,
        onFail,
      })
    );
  };
  const getLocationList = () => {
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setTotalRecords(res?.totalRecords);
      _.forEach(res?.results, (item) => {
        item.key = item.id;
      });
      setData(res?.results);
    };
    getLocations({
      data: reqBody,
      onSuccess,
      onFail,
    });
  };
  const deactivateMachineById = (id) => {
    //eslint-disable-line
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = () => {
      //eslint-disable-line
      openNotification({
        message: "Success",
        description: "Machine deactivated successfully!",
      });
      getLocationList();
    };
    dispatch(
      deactivateMachine({
        data: { id },
        onSuccess,
        onFail,
      })
    );
  };
  const getStateList = () => {
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setStates(res);
    };
    dispatch(
      fetchStates({
        onSuccess,
        onFail,
      })
    );
  };
  const loading = useSelector(
    (state) =>
      state.location.fetchLocationsLoading || state.common.fetchStatesLoading
  );
  return (
    <>
      {loading && <Loading />}
      <Header>Kiosks</Header>
      <div className="card box-margin-main">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <SearchBar onChange={handleOnChange} />
          </div>
          <div className="col-md-6 col-sm-12">
            <div align="right">
              <ExportButton onClick={exportsMachinesList} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-2 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 entries-filter">City:</div>
            <span>
              <CustomInput
                width="191px"
                size="small"
                name="city"
                value={reqBody.city}
                onChange={handleCity}
              />
            </span>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-2 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 location-filter">State:</div>
            <CustomSelect
              labelKey="stateName"
              width={"191px"}
              placeholder="Select"
              onChange={handleStateChange}
              options={states}
            />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-2 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 entries-filter">Zip Code:</div>
            <span>
              <CustomInput
                width="160px"
                size="small"
                name="dmaZip"
                value={reqBody.dmaZip}
                onChange={handleZip}
              />
            </span>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 mt-20 col-sm-12 d-flex w-100 justify-content-end">
            <div className="mt-6 location-filter">Show &nbsp;</div>
            {<ItemsOnPage />}
            <div className="mt-6 location-filter">&nbsp; items</div>
          </div>
        </div>
        <div className="mt-30">
          <CustomTable
            columns={locationColumns}
            // columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={
              totalRecords > reqBody.pageSize
                ? {
                    itemRender,
                    pageSize: reqBody.pageSize,
                    total: totalRecords,
                    current: reqBody.pageIndex + 1,
                    position: ["bottomCenter"],
                  }
                : false
            }
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: (payload) => dispatch(fetchLocations(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Locations);
