import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DatePicker } from "antd";
import ExportButton from "../../../common/components/ExportButton";
import SearchBar from "../../../common/components/SearchBar";
import CustomSelect from "../../../common/components/CustomSelect";
import CustomTable from "../../../common/components/CustomTable";
import { exportsEntries } from "../../../redux/modules/Entries/entriesActions";
import { fetchProducts } from "../../../redux/modules/Common/commonActions";
import exportCSVFile from "../../../utils/JSON2csv";
import {
  formatPhoneNumber,
  openNotification,
  sortArray,
} from "../../../utils/common";
import Loading from "../../../common/components/Loading";


function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a style={{ color: "#01b2aa" }}>Previous</a>;
  }
  if (type === "next") {
    return <a style={{ color: "#01b2aa" }}>Next</a>;
  }
  return originalElement;
}

const MachineEntries = ({ entriesList, reqBody, setReqBody, totalRecords }) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]); // eslint-disable-line
  // eslint-disable-line

  useEffect(() => {
    // getEntriesList();
  }, [reqBody]);

  useEffect(() => {
    getStateList();
  }, []);

  const entriesDashboardColumns = [
    {
      title: "Comms?",
      dataIndex: "isAllowNewsletter",
      key: "isAllowNewsletter",
      render: (text) => (text ? 'Yes' : 'No'),
      sorter: (a, b) => sortArray(a, b, "isAllowNewsletter"),
    },
    {
      title: "Name",
      dataIndex: "customerFirstName",
      key: "customerFirstName",
      render: (text, data) =>
        data?.customerFirstName + " " + data?.customerLastName,
      sorter: (a, b) => sortArray(a, b, "customerFirstName"),
    },
    {
      title: "Phone",
      dataIndex: "customerPhone",
      key: "customerPhone",
      render: (text) => formatPhoneNumber(text),
      sorter: (a, b) => sortArray(a, b, "customerPhone"),
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      render: (text) => <span style={{ color: "#0e4c8c" }}>{text}</span>,
      sorter: (a, b) => sortArray(a, b, "customerEmail"),
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "productName"),
    }, // eslint-disable-line
    {
      title: "Message",
      dataIndex: "labelMessage",
      key: "labelMessage",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "labelMessage"),
    },
    {
      title: "Date",
      dataIndex: "formattedDate",
      key: "LastUpdatedOn",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "LastUpdatedOn"),
    },
  ];
  const handleChange = (pagination, filters, sorter) => {
    if (
      reqBody.pageIndex !== pagination.current - 1 ||
      reqBody.sortBy !== sorter.field ||
      reqBody.isAscending !== (sorter.order === "ascend") ||
      sorter.order === undefined
    )
      setReqBody({
        ...reqBody,
        pageIndex:
          pagination.current >= 0 ? pagination.current - 1 : reqBody.pageIndex,
        sortBy: sorter.order === undefined ? "" : sorter.columnKey,
        isAscending: sorter.order === "ascend",
      });
  };
  const handlePageSize = (e) => {
    // eslint-disable-line
    setReqBody({ ...reqBody, pageSize: e.target.value, pageIndex: 0 });
  };

  const handleOnChange = (e) => {
    setReqBody({ ...reqBody, keyword: e.target.value });
  };

  const handleInputs = (event) => {
    setReqBody({ ...reqBody, [event.target.name]: event.target.value });
  };

  const ItemsOnPage = () => {
    return (
      <CustomSelect
        width="73px"
        notAllowClear
        value={reqBody.pageSize}
        onChange={handlePageSize}
        options={[10, 20, 30]}
      />
    );
  };

  const exportsEntriesList = () => {
    const onFail = () => {
      console.log("Error");
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      if (res?.results?.length) {
        const finalArray = res?.results?.map((i) => ({
          CustomerFirstName: i?.customerFirstName,
          CustomerPhone: i?.customerPhone,
          CustomerEmail: i?.customerEmail,
          ProductName: i?.productName,
          LabelMessage: i?.labelMessage || "-",
          Date: i?.lastUpdatedOn,
          isAllowNewsletter:i?.isAllowNewsletter ? "Yes" : "No"
        }));
        exportCSVFile(finalArray, "entries");
      }
    };
    dispatch(
      exportsEntries({
        data: reqBody,
        onSuccess,
        onFail,
      })
    );
  };
  const handleProductChange = (e) => {
    setReqBody({
      ...reqBody,
      productNames: e.target.value === undefined ? [] : [e.target.value],
    });
  };

  const getStateList = () => {
    const onFail = () => {
      console.log("Error");
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setProducts(res);
    };
    dispatch(
      fetchProducts({
        onSuccess,
        onFail,
      })
    );
  };
  const { RangePicker } = DatePicker;
  const loading = useSelector(
    (state) =>
      state.entries.fetchEntriesLoading || state.common.fetchStatesLoading
  );
  return (
    <>
      {loading && <Loading />}
      <div className="card box-margin-main w-100">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <SearchBar onChange={handleOnChange} />
          </div>
          <div className="col-md-6 col-sm-12">
            <div align="right">
              <ExportButton
                onClick={() => {
                  if (entriesList?.length) {
                    const finalArray = entriesList?.map((i) => ({
                      CustomerFirstName: i?.customerFirstName || "-",
                      CustomerPhone: i?.customerPhone || "-",
                      CustomerEmail: i?.customerEmail || "-",
                      ProductName: i?.productName || "-",
                      Date: i?.formattedDate || "-",
                      isAllowNewsletter:i?.isAllowNewsletter ? "Yes":"No"
                    }));
                    exportCSVFile(finalArray, "EntriesList");
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row style-font fw-400 w-100">
          <div className="col-md-6 col-lg-4 col-xl-4 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 entries-filter">Date:</div>
            <RangePicker
              allowClear={true}
              onChange={(e) => {
                if (e?.length) {
                  setReqBody({
                    ...reqBody,
                    startDate: e[0].format("YYYY-MM-DD"),
                    endDate: e[1].format("YYYY-MM-DD"),
                  });
                } else {
                  setReqBody({
                    ...reqBody,
                    startDate: "",
                    endDate: "",
                  });
                }
              }}
              value={
                reqBody?.startDate
                  ? reqBody?.endDate
                    ? [
                        moment(reqBody?.startDate, "YYYY/MM/DD"),
                        moment(reqBody?.endDate, "YYYY/MM/DD"),
                      ]
                    : [moment(reqBody?.startDate, "YYYY/MM/DD")]
                  : null
              }
            />
          </div>
          <div className="col-xl-4 col-md-6 col-lg-4 mt-20 col-sm-12 d-flex ">
            <div className="mt-6 mr-18">Product:</div>
            <CustomSelect
              labelKey="productName"
              width="270px"
              value={reqBody.productNames}
              name={"productNames"}
              placeholder="Select"
              onChange={handleProductChange}
              options={products}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-lg-4 mt-20 col-sm-12 d-flex w-100 justify-content-end">
            <div className="mt-6">Show &nbsp;</div>
            {<ItemsOnPage />}
            <div className="mt-6">&nbsp; items</div>
          </div>
        </div>
        <div className="mt-30">
          <CustomTable
            columns={entriesDashboardColumns}
            // columns={columns}
            dataSource={entriesList}
            onChange={handleChange}
            pagination={
              totalRecords > reqBody.pageSize
                ? {
                    itemRender,
                    pageSize: reqBody.pageSize,
                    total: totalRecords,
                    current: reqBody.pageIndex + 1,
                    position: ["bottomCenter"],
                  }
                : false
            }
          />
        </div>
      </div>
    </>
  );
};

export default MachineEntries;

// const data = [//eslint-disable-line
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "Connection Error",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "No Issue",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "Connection Error",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "Connection Error",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "No Issue",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "Connection Error",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "No Issue",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "Connection Error",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "Connection Error",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },
//   {
//     storeName: "Total Wine 55",
//     machineId: "Machine #12",
//     address: "123 Main Street",
//     gender: "Dallas",
//     state: "TX",
//     zip: "76214",
//     issue: "Connection Error",
//     lastUpdated: "06/25/2021 - 12:00 PM"
//   },//eslint-disable-line
// ];
