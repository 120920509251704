import React from "react";
import AuditBox from "../../../common/components/AuditBox";
import BarGraph from "./BarGraph";
import ChartCardsDiv from "./ChartCardsDiv";

const Overview = ({
  kioskDetails,
  statisticsList,
  auditList,
  getStatisticList,
  fromDashboard,
  fromLocation,
  storeList,
  setCurrentTab,
}) => {
  return (
    <div className="w-100">
      <ChartCardsDiv
        kioskDetails={kioskDetails}
        fromDashboard={fromDashboard}
      />
      <div
        className="overview justify-content-center"
        style={{ marginBottom: "80px" }}
      >
        <div className="div-1">
          <div className="graph-div">
            <BarGraph
              fromDashboard={fromDashboard}
              storeList={storeList}
              getStatisticList={getStatisticList}
              statisticsList={statisticsList}
              fromLocation={fromLocation}
            />
          </div>
        </div>
        <div className="div-2">
          <AuditBox
            auditList={auditList}
            fromLocation={fromLocation}
            fromDashboard={fromDashboard}
            setCurrentTab={setCurrentTab}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
