import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "../../redux/modules/userDetails/userDetailsActions";
import CustomInput from "../../common/components/CustomInput";
import BlueButton from "../../common/components/BlueButton";
import SignInBox from "../../common/components/SignInBox";
import {
  displayErrorMessage,
  openNotification,
  validate
} from "../../utils/common";
import "./ForgetPassword.scss";
import Loading from "../../common/components/Loading";

const ForgetPassword = props => {
  const { forgotPassword } = props;
  const [error, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  const onReset = () => {
    const errMsg = validate("email", email);
    if (errMsg) return setErrorMsg(errMsg);
    const onFail = err => setErrorMsg(displayErrorMessage(err));
    const onSuccess = res => {
      if (res.isValid) {
        openNotification({
          message: "Success",
          description: "Reset password link successfully sent to you email!"
        });
        setErrorMsg();
        history.push("/");
      } else setErrorMsg(displayErrorMessage());
    };
    forgotPassword({
      data: { email },
      onSuccess,
      onFail
    });
  };

  const onChange = ({ target: { value } }) => {
    setEmail(value);
  };
  const loading = useSelector(state => state.userDetails.forgotPasswordLoading);
  return (
    <div>
      {loading && <Loading noMarginLeft />}
      <SignInBox className="forgot-password">
        <div className="password-title fw-600" align="left">
          Forgot Password?
        </div>
        <div>
          <div className="forgot-password-description">
            Please enter your email address, and we will send you a link to
            reset your password.
          </div>
          <CustomInput
            className="sign-in-input mt-24 forgot-input fw-300"
            placeholder="Enter your email address"
            prefix={
              <img
                width="12px"
                src="https://i.ibb.co/42yLFQq/userIcon.png"
                alt=""
              />
            }
            value={email || ""}
            name="email"
            onChange={onChange}
          />
        </div>
        <small className="text-danger">{error || ""}</small>
        <div className="mt-20" align="left">
          <BlueButton onClick={onReset}>Reset</BlueButton>
        </div>
      </SignInBox>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.userDetails.forgotPasswordLoading
  };
};
const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: payload => dispatch(forgotPassword(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
