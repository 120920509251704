import { handleActions } from "redux-actions";
import { initialCommonState } from "./initialCommonState";
import { CommonConstants } from "./commonConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const commonReducer = handleActions(
         {
           [requestSuccess(CommonConstants.FETCH_STATES)]: (
             state
             // action
           ) => ({
             ...state,
             fetchStatesLoading: false,
             fetchStatesFailure: false,
             fetchStatesLoaded: true,
           }),
           [requestPending(CommonConstants.FETCH_STATES)]: (state) => ({
             ...state,
             fetchStatesLoading: true,
             fetchStatesLoaded: false,
             fetchStatesFailure: false,
           }),
           [requestFail(CommonConstants.FETCH_STATES)]: (state) => ({
             ...state,
             fetchStatesLoading: false,
             fetchStatesLoaded: true,
             fetchStatesFailure: true,
           }),
           [requestSuccess(CommonConstants.FETCH_PRODUCTS)]: (
             state
             // action
           ) => ({
             ...state,
             fetchProductsLoading: false,
             fetchProductsFailure: false,
             fetchProductsLoaded: true,
           }),
           [requestPending(CommonConstants.FETCH_PRODUCTS)]: (state) => ({
             ...state,
             fetchProductsLoading: true,
             fetchProductsFailure: false,
             fetchProductsLoaded: false,
           }),
           [requestFail(CommonConstants.FETCH_PRODUCTS)]: (state) => ({
             ...state,
             fetchProductsLoading: false,
             fetchProductsFailure: true,
             fetchProductsLoaded: true,
           }),
           [requestSuccess(CommonConstants.FETCH_TIMEZONES)]: (
             state
             // action
           ) => ({
             ...state,
             fetchTimeZonesLoading: false,
             fetchTimeZonesFailure: false,
             fetchTimeZonesLoaded: true,
           }),
           [requestPending(CommonConstants.FETCH_TIMEZONES)]: (state) => ({
             ...state,
             fetchTimeZonesLoading: true,
             fetchTimeZonesFailure: false,
             fetchTimeZonesLoaded: false,
           }),
           [requestFail(CommonConstants.FETCH_TIMEZONES)]: (state) => ({
             ...state,
             fetchTimeZonesLoading: false,
             fetchTimeZonesFailure: true,
             fetchTimeZonesLoaded: true,
           }),
         },
         initialCommonState()
       );
