import React from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown } from "antd";

const ProfileMenu = props => {
  let history = useHistory();
  const menu = (
    <Menu style={{ top: "20px" }}>
      <Menu.Item key={1} onClick={() => history.push("/profile")}>
        Profile
      </Menu.Item>
      <Menu.Item key={2} onClick={props.onLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown trigger={["click"]} overlay={menu} placement="bottomRight">
      {props.children}
    </Dropdown>
  );
};

export default ProfileMenu;
