import React from "react";
import "./ChartCard.scss";
import {Area, AreaChart, ResponsiveContainer} from "recharts";

const ChartCard = ({fillColor, digit, label}) => {
    const increamentData = [
        {name: "Page A", price: 800},
        {name: "Page B", price: 1500},
        {name: "Page C", price: 700},
        {name: "Page D", price: 1900},
        {name: "Page D", price: 1000}//eslint-disable-line
    ];
    return (
        <div className="ChartCard">
            <div className="text-left">
                <div className="digit-div fw-500">{(digit || digit === 0) ? digit : '-' }</div>
                <div className="label-div fw-400">{label}</div>
            </div>
            <div className="chart-div">
                <ResponsiveContainer width="100%" height={50}>
                    <AreaChart
                        data={increamentData}
                        dot={false}
                        margin={{top: 0, right: 0, left: 0, bottom: 0}}
                    >
                        <defs>
                            <linearGradient id="color4" x1="0" y1="0" x2="1" y2="1">
                                <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9}/>
                                <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9}/>
                            </linearGradient>
                        </defs>
                        <Area
                            dataKey="price"
                            type="monotone"
                            strokeWidth={0}
                            stackId="1"
                            stroke="#4D95F3"
                            fill={fillColor}
                            fillOpacity={1}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ChartCard;
