import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import ThreeDotIcon from "../../../assets/icon/ThreeDotIcon.svg";
import {Popconfirm} from 'antd';

const ActionMenu = ({ menuOptions }) => {
    const history = useHistory();
    const actionMenu = (
        <Menu>
            {menuOptions.map((item, index) => {
                if (item?.confirm)
                    return (
                        <Popconfirm key={index} title={item?.confirmText} onConfirm={() => {
                            item.onClick ? item.onClick() : history.push(item?.to);
                        }} okText="Yes" cancelText="No">
                            <Menu.Item>
                                {item.label}
                            </Menu.Item>
                        </Popconfirm>
                    );
                return (
                    <Menu.Item
                        onClick={() => {
                            item.onClick ? item.onClick() : history.push(item?.to);
                        }}
                        key={index}
                    >
                        {item.label}
                    </Menu.Item>
                )
            })}
        </Menu>
    );
    return (
        <Dropdown trigger={["click"]} overlay={actionMenu} placement="bottomRight">
            <div
                style={{
                    borderRadius: "50%",
                    height: "36px",
                    width: "36px",
                    border: "1px solid #D9D9D9",
                    padding: "6px 9px"
                }}
            >
                <img src={ThreeDotIcon} alt="" />
            </div>
        </Dropdown>
    );
};

export default ActionMenu;
