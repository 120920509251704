import React from "react";
import { Button } from "antd";
import "./CustomButton.scss";

export const ButtonWithIcon = props => {
  const { className, onClick } = props;
  return (
    <div className="customButton2" align="left">
      <Button
        className={className || ""}
        icon={<img alt="" src={props.icon} height="17px" />}
        onClick={onClick || null}
      >
        {props.children}
      </Button>
    </div>
  );
};
