import { handleActions } from "redux-actions";
import { initialStoresState } from "./initialStoresState";
import { StoresConstants } from "./storesConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const storesReducer = handleActions(
  {
    [requestSuccess(StoresConstants.FETCH_STORES)]: state => ({
      ...state,
      fetchStoresLoading: false,
      fetchStoresFailure: false,
      fetchStoresLoaded: true
    }),
    [requestPending(StoresConstants.FETCH_STORES)]: state => ({
      ...state,
      fetchStoresLoading: true,
      fetchStoresLoaded: false,
      fetchUserDetailsFailure: false
    }),
    [requestFail(StoresConstants.FETCH_STORES)]: state => ({
      ...state,
      fetchStoresLoading: false,
      fetchStoresLoaded: true,
      fetchStoresFailure: true
    }),
    [requestSuccess(StoresConstants.FETCH_STORE_BY_ID)]: state => ({
      ...state,
      fetchStoreByIdLoading: false,
      fetchStoreByIdFailure: false,
      fetchStoreByIdLoaded: true
    }),
    [requestPending(StoresConstants.FETCH_STORE_BY_ID)]: state => ({
      ...state,
      fetchStoreByIdLoading: true,
      fetchStoreByIdFailure: false,
      fetchStoreByIdLoaded: false
    }),
    [requestFail(StoresConstants.FETCH_STORE_BY_ID)]: state => ({
      ...state,
      fetchStoreByIdLoading: false,
      fetchStoreByIdFailure: true,
      fetchStoreByIdLoaded: true
    }),
    [requestSuccess(StoresConstants.FETCH_STORE_DASHBOARD)]: state => ({
      ...state,
      fetchStoreDashboardLoading: false,
      fetchStoreDashboardFailure: false,
      fetchStoreDashboardLoaded: true
    }),
    [requestPending(StoresConstants.FETCH_STORE_DASHBOARD)]: state => ({
      ...state,
      fetchStoreDashboardLoading: true,
      fetchStoreDashboardFailure: false,
      fetchStoreDashboardLoaded: false
    }),
    [requestFail(StoresConstants.FETCH_STORE_DASHBOARD)]: state => ({
      ...state,
      fetchStoreDashboardLoading: false,
      fetchStoreDashboardFailure: true,
      fetchStoreDashboardLoaded: true
    }),
    [requestSuccess(StoresConstants.UPDATE_STORE_DETAILS)]: state => ({
      ...state,
      updateStoreDetailsLoading: false,
      updateStoreDetailsFailure: false,
      updateStoreDetailsLoaded: true
    }),
    [requestPending(StoresConstants.UPDATE_STORE_DETAILS)]: state => ({
      ...state,
      updateStoreDetailsLoading: true,
      updateStoreDetailsFailure: false,
      updateStoreDetailsLoaded: false
    }),
    [requestFail(StoresConstants.UPDATE_STORE_DETAILS)]: state => ({
      ...state,
      updateStoreDetailsLoading: false,
      updateStoreDetailsFailure: true,
      updateStoreDetailsLoaded: true
    })
  },
  initialStoresState()
);
