import { handleActions } from "redux-actions";
import { initialEntriesState } from "./initialEntriesState";
import { EntriesConstants } from "./entriesConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const entriesReducer = handleActions(
  {
    [requestSuccess(EntriesConstants.FETCH_ENTRIES)]: state => ({
      ...state,
      fetchEntriesLoading: false,
      fetchEntriesFailure: false,
      fetchEntriesLoaded: true
    }),
    [requestPending(EntriesConstants.FETCH_ENTRIES)]: state => ({
      ...state,
      fetchEntriesLoading: true,
      fetchEntriesFailure: false,
      fetchEntriesLoaded: false
    }),
    [requestFail(EntriesConstants.FETCH_ENTRIES)]: state => ({
      ...state,
      fetchEntriesLoading: false,
      fetchEntriesFailure: true,
      fetchEntriesLoaded: true
    }),
    [requestSuccess(EntriesConstants.EXPORTS_ENTRIES)]: state => ({
      ...state,
      exportEntriesLoading: false,
      exportEntriesFailure: false,
      exportEntriesLoaded: true
    }),
    [requestPending(EntriesConstants.EXPORTS_ENTRIES)]: state => ({
      ...state,
      exportEntriesLoading: true,
      exportEntriesFailure: false,
      exportEntriesLoaded: false
    }),
    [requestFail(EntriesConstants.EXPORTS_ENTRIES)]: state => ({
      ...state,
      exportEntriesLoading: false,
      exportEntriesFailure: true,
      exportEntriesLoaded: true
    }),
    [requestSuccess(EntriesConstants.FETCH_STATISTICS)]: state => ({
      ...state,
      fetchStatisticsLoading: false,
      fetchStatisticsFailure: false,
      fetchStatisticsLoaded: true
    }),
    [requestPending(EntriesConstants.FETCH_STATISTICS)]: state => ({
      ...state,
      fetchStatisticsLoading: true,
      fetchStatisticsFailure: false,
      fetchStatisticsLoaded: false
    }),
    [requestFail(EntriesConstants.FETCH_STATISTICS)]: state => ({
      ...state,
      fetchStatisticsLoading: false,
      fetchStatisticsFailure: true,
      fetchStatisticsLoaded: true
    })
  },
  initialEntriesState()
);
