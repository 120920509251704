export const initialUserDetailsState = () => ({
  fetchUserDetailsLoading: false,
  fetchUserDetailsFailure: false,
  fetchUserDetailsLoaded: false,
  createUserLoading: false,
  createUserFailure: false,
  createUserLoaded: false,
  updateUserDetailsLoading: false,
  updateUserDetailsFailure: false,
  updateUserDetailsLoaded: false,
  updateUserStatusLoading: false,
  updateUserStatusFailure: false,
  updateUserStatusLoaded: false,
  updateUserImageLoading: false,
  updateUserImageFailure: false,
  updateUserImageLoaded: false,
  deleteUserImageLoading: false,
  deleteUserImageFailure: false,
  deleteUserImageLoaded: false,
  getLoginDetailsLoading: false,
  getLoginDetailsFailure: false,
  getLoginDetailsLoaded: false,
  logoutLoading: false,
  logoutFailure: false,
  logoutLoaded: false,
  resetPasswordLoading: false,
  resetPasswordFailure: false,
  resetPasswordLoaded: false,
  forgotPasswordLoading: false,
  forgotPasswordFailure: false,
  forgotPasswordLoaded: false,
  refreshTokenLoading: false,
  refreshTokenFailure: false,
  refreshTokenLoaded: false,
  updatePasswordLoading: false,
  updatePasswordFailure: false,
  updatePasswordLoaded: false,
  details: null,
});
