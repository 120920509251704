import React, {useEffect, useState} from "react";
import SearchBar from "../../common/components/SearchBar";
import CustomSelect from "../../common/components/CustomSelect";
import CustomTable from "../../common/components/CustomTable";
import BlueButton from "../../common/components/BlueButton";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import "./Accounts.scss";
import {
    fetchAccounts,
    fetchRoles
} from "../../redux/modules/Accounts/accountActions";
import moment from "moment";
import Header from "../../common/components/Header";
import AddAccountModal from "./components/AddAccountModal";
import _ from "lodash";
import {
    displayErrorMessage,
    formatPhoneNumber,
    openNotification
} from "../../utils/common";
import {
    createUser,
    updateUserImage,
    updateUserStatus
} from "../../redux/modules/userDetails/userDetailsActions";
import ActionMenu from "../../common/components/ActionMenu";
import Loading from "../../common/components/Loading";

function itemRender(current, type, originalElement) {
    if (type === "prev") {
        return <a>Previous</a>;
    }
    if (type === "next") {
        return <a>Next</a>;
    }
    return originalElement;
}

const Accounts = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [visibleModal, setVisibleModal] = useState(false); // eslint-disable-line
    const [errorMsg, setErrorMsg] = useState(); // eslint-disable-line

    // const { getLocations } = props;
    const [reqBody, setReqBody] = useState({
        keyword: "",
        isActive: null,
        roleId: "",
        sortBy: "",
        pageSize: 10,
        pageIndex: 0,
        isAscending: true
    });
    const [totalRecords, setTotalRecords] = useState(0); // eslint-disable-line
    const [data, setData] = useState([]); // eslint-disable-line
    const [roles, setRoles] = useState([]); // eslint-disable-line

    useEffect(() => {
        if (!roles.length) getRolesList();
    }, []);
    useEffect(() => {
        getAccountList();
    }, [reqBody]);
    const handleChange = (pagination, filters, sorter) => {
        if (
            reqBody.pageIndex !== pagination.current - 1 ||
            reqBody.sortBy !== sorter.field ||
            reqBody.isAscending !== (sorter.order === "ascend") ||
            sorter.order === undefined
        )
            setReqBody({
                ...reqBody,
                pageIndex:
                    pagination.current >= 0 ? pagination.current - 1 : reqBody.pageIndex,
                sortBy: sorter.order === undefined ? "" : sorter.field,
                isAscending: sorter.order === "ascend"
            });
    };
    const accountsColumns = [
      {
        title: "Full Name",
        dataIndex: "fullName",
        key:"fillName",
        sorter: () => {},
        render: (text, data) => (
          <span
            className="cursor-pointer fw-500"
            onClick={() => history.push(`/administrators/${data?.id}`)}
            style={{ color: "#0e4c8c" }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key:"email",
        sorter: () => {},
        render: (email) => (
          <a
            style={{ color: "#0e4c8c" }}
            className="fw-500"
            href={`mailto:${email}`}
          >
            {email}
          </a>
        ),
      },
      {
        title: "Phone",
        dataIndex: "phoneNumber",
        key:"phoneNumber",
        render: (text) => formatPhoneNumber(text),
        sorter: () => {},
      },
      {
        title: "Role",
        dataIndex: "roleName",
        key:"roleName",
        sorter: () => {},
      },
      {
        title: "Last Login",
        dataIndex: "lastLoginDateTime",
        key:"lastLoginDateTime",
        sorter: () => {},
        render: (text) => {
          if (text) {
            return moment(text).format("MM/DD/YYYY - hh:mm A");
          } else {
            return "Not Logged In Yet";
          }
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key:"actions",
        render: (text, data) => (
          <ActionMenu
            menuOptions={[
              { label: "View", to: `/administrators/${data?.id}` },
              {
                label: data?.isActive ? "Deactivate" : "Activate",
                confirm: true,
                confirmText: `Are you sure you want to ${
                  data?.isActive ? "Deactivate" : "Activate"
                } the user?`,
                onClick: () => {
                  updateAccountStatus({
                    id: data?.id,
                    isActive: !data?.isActive,
                  });
                },
              }, //eslint-disable-line
            ]}
          />
        ),
      }, // eslint-disable-line
    ];

    const handleSearchInputs = event => {
        setReqBody({...reqBody, [event.target.name]: event.target.value});
    };
    const handleStatusInputs = event => {
        setReqBody({
            ...reqBody,
            [event.target.name]:
                event.target.value === undefined
                    ? undefined
                    : event.target.value === "Active"
        });
    };
    const handleModal = () => {
        setErrorMsg("");
        setVisibleModal(!visibleModal);
    };
    // const handleStateChange = value => {
    //   setReqBody({ ...reqBody, state: value });
    // };

    const handlePageSize = e => {
        // eslint-disable-line
        setReqBody({...reqBody, pageSize: e.target.value, pageIndex: 0});
    };

    const handleOnChange = e => {
        setReqBody({...reqBody, keyword: e.target.value});
    };

    // const handleDmaInputs = event => {
    //   setReqBody({ ...reqBody, [event.target.name]: event.target.value });
    // };

    const ItemsOnPage = () => {
        return (
            <CustomSelect
                width="73px"
                notAllowClear
                value={reqBody.pageSize}
                onChange={handlePageSize}
                options={[10, 20, 30]}
            />
        );
    };
    const updateAccountStatus = data => {
        const onFail = () => {
            console.log("Error");
            openNotification({
                message: "Error",
                description: "Error while processing your request!",
                error: true
            });
        };
        const onSuccess = () => {
            getAccountList();
            openNotification({
                message: "Success",
                description: "User status updated successfully!"
            });
        };
        dispatch(
            updateUserStatus({
                data,
                onSuccess,
                onFail
            })
        );
    };
    const getAccountList = () => {
        const onFail = () => {
            console.log("Error");
            openNotification({
                message: "Error",
                description: "Error while processing your request!",
                error: true
            });
        };
        const onSuccess = res => {
            //eslint-disable-line
            setTotalRecords(res?.totalRecords || 0);
            _.forEach(res?.results, item => {
                item.key = item.id;
            });
            setData(res?.results);
        };
        dispatch(
            fetchAccounts({
                data: reqBody,
                onSuccess,
                onFail
            })
        );
    };

    const getRolesList = () => {
        //eslint-disable-line
        const onFail = res => {
            openNotification({
                message: "Error",
                description:
                    res?.date?.message || "Error while processing your request!",
                error: true
            });
        };
        const onSuccess = res => {
            //eslint-disable-line
            setRoles(res);
        };
        dispatch(
            fetchRoles({
                data: {},
                onSuccess,
                onFail
            })
        );
    };
    const addUser = (data, imageObj) => {    //eslint-disable-line
        const onFail = res => {
            setErrorMsg(res?.data?.message);
            // openNotification({
            //   message: "Error",
            //   description:
            //     res?.data?.message || "Error while processing your request!",
            //   error: true
            // });

        };
        const onSuccess = res => {
            //eslint-disable-line
            updateImage(res, imageObj);
        };
        dispatch(
            createUser({
                data,
                onSuccess,
                onFail
            })
        );
    };
    const updateImage = (userId, e) => {// eslint-disable-line
        const formData = new FormData();
        formData.append("Id", userId);
        if (e?.target?.files && e?.target?.files[0]) {
            formData.append("ProfileImage", e.target.files[0]);
            const onSuccess = response => {
                console.log(response);
                handleModal();
                openNotification({
                    message: "Success",
                    description: "User created successfully!"
                });
            };
            const onFail = () => {
                displayErrorMessage("Error while fetching vendors.");
            };
            dispatch(updateUserImage({data: formData, onSuccess, onFail}));
        } else {
            handleModal();
            openNotification({
                message: "Success",
                description: "User created successfully!"
            });
        }
    };
    const loading = useSelector(
        state =>
            state.account.fetchRolesLoading ||
            state.account.fetchAccountsLoading ||
            state.userDetails.createUserLoading
    );
    return (
      <>
        {loading && <Loading />}
        {visibleModal && (
          <AddAccountModal
            errorMsg={errorMsg}
            addUser={addUser}
            rolesList={roles}
            visible={visibleModal}
            onClose={handleModal}
          />
        )}
        <Header>Administrators</Header>
        <div className="account">
          <div className="card">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <SearchBar name="keyword" onChange={handleOnChange} />
              </div>
              <div className="col-md-6 col-sm-12">
                <div align="right">
                  <BlueButton onClick={handleModal}>
                    Add Administrators
                  </BlueButton>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-4  mt-20 col-sm-12 d-flex">
                <div className="mt-6 mr-18 account-filter">Status:</div>
                <CustomSelect
                  width={"191px"}
                  name="isActive"
                  placeholder="Select"
                  onChange={handleStatusInputs}
                  options={[
                    { name: "Active", id: "true" },
                    { name: "Inactive", id: "false" }, //eslint-disable-line
                  ]}
                />
              </div>
              <div className="col-md-4 col-lg-2 mt-20 col-sm-12 d-flex w-100 ">
                <div className="mt-6 account-filter">Show &nbsp;</div>
                {<ItemsOnPage />}
                <div className="mt-6 account-filter">&nbsp; items</div>
              </div>
            </div>
            <div className="mt-30">
              <CustomTable
                columns={accountsColumns}
                // columns={columns}
                dataSource={data}
                className="table-50px"
                onChange={handleChange}
                pagination={
                  totalRecords > reqBody.pageSize
                    ? {
                        itemRender,
                        pageSize: reqBody.pageSize,
                        total: totalRecords,
                        current: reqBody.pageIndex + 1,
                        position: ["bottomCenter"],
                      }
                    : false
                }
              />
            </div>
          </div>
        </div>
      </>
    );
};

export default Accounts;
