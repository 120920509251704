export const initialStoresState = () => ({
  fetchStoresLoading: false,
  fetchStoresFailure: false,
  fetchStoresLoaded: false,
  fetchStoreByIdLoading: false,
  fetchStoreByIdFailure: false,
  fetchStoreByIdLoaded: false,
  fetchStoreDashboardLoading: false,
  fetchStoreDashboardFailure: false,
  fetchStoreDashboardLoaded: false,
  updateStoreDetailsLoading: false,
  updateStoreDetailsFailure: false,
  updateStoreDetailsLoaded: false
});
