import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { connectRouter } from "connected-react-router";
import userDetailsSaga from "./userDetails/userDetailsSaga";
import { userDetailsReducer } from "./userDetails/userDetailsReducer";
import locationsSaga from "./Locations/locationsSaga";
import { accountReducer } from "./Accounts/accountReducer";
import accountSaga from "./Accounts/accountSaga";
import { entriesReducer } from "./Entries/entriesReducer";
import entriesSaga from "./Entries/entriesSaga";
import { commonReducer } from "./Common/commonReducer";
import commonSaga from "./Common/commonSaga";
import { locationsReducer } from "./Locations/locationsReducer";
import { storesReducer } from "./Stores/storesReducer";
import storesSaga from "./Stores/storesSaga";
import { auditLogReducer } from "./AuditLog/auditLogReducer";
import auditLogSaga from "./AuditLog/auditLogSaga";
import { dashboardReducer } from "./Dashboard/dashboardReducer";
import dashboardSaga from "./Dashboard/dashboardSaga";

export const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    common: commonReducer,
    dashboard: dashboardReducer,
    userDetails: userDetailsReducer,
    location: locationsReducer,
    account: accountReducer,
    entries: entriesReducer,
    store: storesReducer,
    auditLog: auditLogReducer
  });

export const rootSaga = function*() {
  yield all([
    commonSaga(),
    dashboardSaga(),
    userDetailsSaga(),
    accountSaga(),
    locationsSaga(),
    storesSaga(),
    auditLogSaga(),
    entriesSaga(),//eslint-disable-line
  ]);
};
