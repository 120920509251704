import React from "react";
import CustomInput from "../../../common/components/CustomInput";
import BlueButton from "../../../common/components/BlueButton";

const Connectivity = ({
  updateDetails,
  errorMsg,
  handelState,
  error,
  storeDetails,
}) => {
  return (
    <div className="update-card" style={{ marginLeft: "-15px" }}>
      <div className="card-header">Kiosk Connectivity</div>
      <div className="card-content">
        <div className="row">
          <div
            className="col-md-12 col-sm-12 fs-17 fw-400 mb-20"
            style={{ color: "#212121" }}
          >
            Access Code Entry
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomInput
              required
              // type="password"
              value={storeDetails?.kioskId}
              name={"kioskId"}
              width="347px"
              maxLength={4}
              onChange={(e) => {
                let value = e.target.value;
                if (!/^[0-9]*$/.test(value)) value = storeDetails.kioskId;
                handelState({ name: "accessCode", value });
              }}
              label="Kiosk ID"
              placeholder="Enter KioskId"
              error={
                (error && !storeDetails?.kioskId
                  ? "Enter valid jailbreak password"
                  : false) || errorMsg?.kioskId
              }
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomInput
              required
              // type="password"
              value={storeDetails?.jailbreakPassword}
              name={"jailbreakPassword"}
              width="347px"
              onChange={handelState}
              label="Jailbreak Password"
              placeholder="Enter Jailbreak Password"
              error={
                (error && !storeDetails?.jailbreakPassword
                  ? "Enter valid jailbreak password"
                  : false) || errorMsg?.jailbreakPassword
              }
            />
          </div>
        </div>
        <div className="row mt-18">
          <div
            className="col-md-12 col-sm-12 fs-17 fw-400 mb-20"
            style={{ color: "#212121" }}
          >
            Team Viewer Configuration
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomInput
              required
              // type="text"
              value={storeDetails?.teamViewerId}
              name={"teamViewerId"}
              width="347px"
              onChange={handelState}
              label="Terminal ID"
              placeholder="Enter team viewer Id"
              error={
                (error && !storeDetails?.teamViewerId
                  ? "Enter valid team viewer Id"
                  : false) || errorMsg?.teamViewerId
              }
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomInput
              required
              // type="password"
              value={storeDetails.teamViewerPassword}
              name={"teamViewerPassword"}
              width="347px"
              onChange={handelState}
              label="Password"
              placeholder="Enter team viewer password"
              error={
                (error && !storeDetails?.teamViewerPassword
                  ? "Enter valid team viewer password"
                  : false) || errorMsg?.teamViewerPassword
              }
            />
          </div>
        </div>
        <div align="right" className="mt-26 mb-6">
          <BlueButton onClick={() => updateDetails(3)}>Save Changes</BlueButton>
        </div>
      </div>
    </div>
  );
};

export default Connectivity;
