import React, { useEffect, useState } from "react";
import SearchBar from "../../../common/components/SearchBar";
import CustomSelect from "../../../common/components/CustomSelect";
import CustomTable from "../../../common/components/CustomTable";
import ThreeDotIcon from "../../../assets/icon/ThreeDotIcon.svg";
import { Dropdown, Menu } from "antd";
import moment from "moment";
import Monitor from "../../../common/components/svg_icons/monitor";
import Printer from "../../../common/components/svg_icons/printer";
import Ink from "../../../common/components/svg_icons/ink";
import Roll from "../../../common/components/svg_icons/paper_roll";
import ExportButton from "../../../common/components/ExportButton";
import exportCSVFile from "../../../utils/JSON2csv";
import { openNotification, sortArray } from "../../../utils/common";
import StatusModal from "../../Locations/components/StatusModal";
import { Tooltip } from "antd";
import { exportsEntries } from "../../../redux/modules/Entries/entriesActions";
import { useDispatch } from "react-redux";
import {config} from "../../../utils/config";

function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
}

const renderStatusData = (item, data, isKioskOnline) => {
  return (
    <div align="center" className="statusIconsmain justify-content-center">
      <div className="statusIcons">
        <Tooltip
          title={
            <StatusModal
              isKioskOnline={isKioskOnline}
              monitor={true}
              issues={true}
            />
          }
          color={isKioskOnline ? `green` : "red"}
        >
          <div>
            <Monitor
              icon_color={isKioskOnline ? `green` : "rgb(255, 86, 48)"}
            />
          </div>
        </Tooltip>
      </div>
      <div className="statusIcons">
        <Tooltip
          overlayInnerStyle={{ width: "360px" }}
          title={
            <StatusModal statusModalData={data} printer={true} issues={true} />
          }
          color={
            data.printerStatusColorCode ||
            (data.printerStatusCode === 0 ||
            data.printerStatusCode === 16 ||
            data.printerStatusCode === 64 ||
            data.printerStatusCode === 128 ||
            (data.printerErrorCode > -1 &&
              data.printerErrorCode !== 24 &&
              data.printerErrorCode !== 27)
              ? `red`
              : data.printerStatusCode === 2 || data.printerStatusCode === 32
              ? `warning`
              : `green`)
          }
        >
          <div>
            <Printer
              icon_color={
                data.printerStatusColorCode ||
                (data.printerStatusCode === 0 ||
                data.printerStatusCode === 16 ||
                data.printerStatusCode === 64 ||
                data.printerStatusCode === 128 ||
                (data.printerErrorCode > -1 &&
                  data.printerErrorCode !== 24 &&
                  data.printerErrorCode !== 27)
                  ? `red`
                  : data.printerStatusCode === 2 ||
                    data.printerStatusCode === 32
                  ? `warning`
                  : `green`)
              }
            />
          </div>
        </Tooltip>
      </div>
      <div className="statusIcons">
        <Tooltip
          title={
            <StatusModal statusModalData={data} ink={true} issues={true} />
          }
          color={
            data?.inkStatusColorCode ||
            (data.inkStatusCode === 0 || data.inkStatusCode === 4
              ? `red`
              : data.inkStatusCode === 2
              ? `sky blue`
              : data.inkStatusCode === 3
              ? `warning`
              : `green`)
          }
        >
          <div>
            <Ink
              icon_color={
                data?.inkStatusColorCode ||
                (data.inkStatusCode === 0 || data.inkStatusCode === 4
                  ? `red`
                  : data.inkStatusCode === 2
                  ? `sky blue`
                  : data.inkStatusCode === 3
                  ? `warning`
                  : `green`)
              }
            />
          </div>
        </Tooltip>
      </div>
      <div className="statusIcons">
        <Tooltip
          title={
            <StatusModal statusModalData={data} roll={true} issues={true} />
          }
          color={
            data.labelRollStatusColorCode ||
            (data.labelRollStatusCode === 0 || data.labelRollStatusCode === 1
              ? `red`
              : data.labelRollStatusCode === 4
              ? `sky blue`
              : data.labelRollStatusCode === 3
              ? `warning`
              : `green`)
          }
        >
          <div>
            <Roll
              icon_color={
                data.labelRollStatusColorCode ||
                (data.labelRollStatusCode === 0 ||
                data.labelRollStatusCode === 1
                  ? `red`
                  : data.labelRollStatusCode === 4
                  ? `sky blue`
                  : data.labelRollStatusCode === 3
                  ? `warning`
                  : `green`)
              }
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const actionMenu = (
  <Menu>
    <Menu.Item
      onClick={() =>
        window.open(
          "https://start.teamviewer.com/device/[TargetComputerId]/authorization/password/mode/control"
        )
      }
      key={1}
    >
      Team Viewer
    </Menu.Item>
    {/*<Menu.Item key={2}>Deactivate</Menu.Item>*/}
  </Menu>
);
const accountsColumns = [
  {
    title: "Status",
    dataIndex: "kioskCurrentStatus",
    key:"kioskCurrentStatus",
    align: "center",
    width: "10%",
    render: (text, data) => renderStatusData(text, data),
    sorter: () => {},
  },
  {
    title: "Issue",
    dataIndex: "issueDescription",
    key:"issueDescription",
    // width:"33%",
    align: "center",
    render: (text) => (
      <span
        style={{
          color: text === "Online" ? "#28A745" : "#DC3545",
        }}
      >
        {text ? text : "No Issue"}
      </span>
    ),
    sorter: () => {},
  },
  {
    title: "Date/Time",
    dataIndex: "lastUpdatedOn",
    key: "lastUpdatedOn",
    align: "center",
    width: "33%",
    render: (text) =>
      text ? moment(text).format("MM/DD/YYYY - hh:mm A") : "-",
    sorter: (a, b) => sortArray(a, b, "lastUpdated"),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: "33%",
    key: "product",
    align: "right",
    render: () => (
      <div align="right" style={{ width: "100%" }}>
        <Dropdown
          trigger={["click"]}
          overlay={actionMenu}
          placement="bottomRight"
        >
          <div
            style={{
              borderRadius: "50%",
              height: "36px",
              width: "36px",
              border: "1px solid #D9D9D9",
              padding: "6px 9px",
            }}
          >
            <img src={ThreeDotIcon} alt="" />
          </div>
        </Dropdown>
      </div>
    ),
  }, // eslint-disable-line
];

const Issues = ({ issueData, getAuditList }) => {
  const dispatch = useDispatch();
  const [reqBody, setReqBody] = useState({
    keyword: "",
    pageSize: 10,
    pageIndex: 0,
    status: "",
    timeZoneName: global?.timeZoneName || config?.TIMEZONE,
  });
  const [totalRecords, setTotalRecords] = useState(0); // eslint-disable-line
  const [data, setData] = useState([]); // eslint-disable-line
  const [roles, setRoles] = useState([]); // eslint-disable-line
  useEffect(() => {
    // exportsEntriesList();
    setData(issueData?.results);
    setTotalRecords(issueData?.totalRecords);
  }, [issueData]);
  useEffect(() => {
    getAuditList(reqBody, 2);
  }, [reqBody]);
  const handleChange = (pagination, filters, sorter, extra) => {
    setReqBody({
      ...reqBody,
      pageIndex:
        pagination.current >= 0 ? pagination.current - 1 : reqBody.pageIndex,
    });
  };

  const handleSearchInputs = (event) => {
    setReqBody({ ...reqBody, [event.target.name]: event.target.value });
  };

  const handlePageSize = (e) => {
    // eslint-disable-line
    setReqBody({ ...reqBody, pageSize: e.target.value, pageIndex: 0 });
  };

  const handleOnChange = (e) => {
    setReqBody({ ...reqBody, keyword: e.target.value });
  };
  const exportsEntriesList = () => {
    // setData(issueData?.results);
    const onFail = () => {
      console.log("Error");
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      exportCSVFile(res, "Issues");
    };
    dispatch(
      exportsEntries({
        data: reqBody,
        onSuccess,
        onFail,
      })
    );
  };

  const ItemsOnPage = () => {
    return (
      <CustomSelect
        width="73px"
        notAllowClear
        value={reqBody.pageSize}
        onChange={handlePageSize}
        options={[10, 20, 30]}
      />
    );
  };

  return (
    <>
      <div className="card w-100" style={{ marginBottom: "80px" }}>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <SearchBar name="keyword" onChange={handleOnChange} />
          </div>
          <div className="col-md-6 col-sm-12">
            <div align="right">
              <ExportButton
                onClick={() => {
                  if (issueData?.results?.length) {
                    const finalArray = issueData?.results?.map((i) => ({
                      kioskId: i?.kioskId || "-",
                      isOnline: i?.isOnline || "-",
                      storeName: i?.storeName || "-",
                      address: i?.address || "-",
                      zip: i?.zip || "-",
                      city: i?.city || "-",
                      stateName: i?.stateName || "-",
                      chainName: i?.chainName || "-",
                      brandName: i?.brandName || "-",
                      printerName: i?.printerName || "-",
                      inkStatusDescription: i?.inkStatusDescription || "-",
                      labelRollStatusDescription:
                        i?.labelRollStatusDescription || "-",
                      printerErrorDescription:
                        i?.printerErrorDescription || "-",
                      printerStatusDescription:
                        i?.printerStatusDescription || "-",
                      lastUpdatedOn: i?.lastUpdatedOn || "-",
                    }));
                    exportCSVFile(finalArray, "Issue");
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row style-font fw-400">
          <div className="col-md-6  mt-20 col-sm-12 d-flex ">
            <div className="mt-6 mr-18">Issue Priority Status:</div>
            <CustomSelect
              width={"191px"}
              name="status"
              placeholder="Select"
              onChange={handleSearchInputs}
              value={reqBody?.status}
              options={[
                "All",
                "High",
                "Medium",
                "Low",
                "No Issue", //eslint-disable-line
              ]}
            />
          </div>
          <div className="col-md-6  mt-20 col-sm-12 d-flex w-100 justify-content-end ">
            <div className="mt-6">Show &nbsp;</div>
            {<ItemsOnPage />}
            <div className="mt-6">&nbsp; items</div>
          </div>
        </div>
        <div className="mt-30">
          <CustomTable
            columns={accountsColumns}
            // columns={columns}
            dataSource={data}
            // className="table-50px"
            onChange={handleChange}
            pagination={
              totalRecords > reqBody.pageSize
                ? {
                    itemRender,
                    pageSize: reqBody.pageSize,
                    total: totalRecords,
                    current: reqBody.pageIndex + 1,
                    position: ["bottomCenter"],
                  }
                : false
            }
          />
        </div>
      </div>
    </>
  );
};

export default Issues;

const data2 = [
  //eslint-disable-line
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  },
  {
    issue: "Connection Error",
    lastUpdated: "06/25/2021 - 12:00 PM",
  }, //eslint-disable-line
];
