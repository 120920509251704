import React from 'react';

const inkSvg = ({ icon_color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1024 1024'
      x='0px'
      y='0px'
    >
      <path
        d='M532.5,7.7c-10.3-10.3-30.8-10.3-41.1,0C476.1,23.1,127,428.6,127,639s174.5,385,385,385s385-174.5,385-385
	S547.9,23.1,532.5,7.7z M537.7,95c10.3,10.3,20.5,25.7,30.8,41.1v826.4c-10.3,0-20.5,5.1-30.8,5.1V95z M619.8,202.7
	c10.3,15.4,20.5,30.8,30.8,46.2v687.8c-10.3,5.1-20.5,10.3-30.8,15.4V202.7z M707,320.8c10.3,15.4,20.5,35.9,30.8,51.3v503
	c-10.3,10.3-20.5,20.5-30.8,25.7V320.8z M789.2,464.5c30.8,61.6,56.5,123.2,56.5,169.4c0,66.7-20.5,128.3-56.5,179.6V464.5z
	 M178.4,639c0-143.7,210.4-426,308-544.1v872.6C311.8,957.3,178.4,813.6,178.4,639z'
        fill={icon_color}
      />
    </svg>
  );
};

export default inkSvg;
