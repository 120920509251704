import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { CommonConstants } from "./commonConstants";

function* getStates(action) {
  yield call(
    request({
      type: CommonConstants.FETCH_STATES,
      method: "GET",
      url: `State`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* getProducts(action) {
  yield call(
    request({
      type: CommonConstants.FETCH_PRODUCTS,
      method: "GET",
      url: `Product`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* getTimeZones(action) {
  yield call(
    request({
      type: CommonConstants.FETCH_TIMEZONES,
      method: "GET",
      url: `TimeZones`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(CommonConstants.FETCH_STATES, getStates);
  yield takeLatest(CommonConstants.FETCH_PRODUCTS, getProducts);
  yield takeLatest(CommonConstants.FETCH_TIMEZONES, getTimeZones);
}
