import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { DashboardConstants } from "./dashboardConstants";

function* getDashboardDetails(action) {
  yield call(
    request({
      type: DashboardConstants.FETCH_DASHBOARD,
      method: "GET",
      url: `Dashboard/GetCounts?timeZoneName=${action.payload.data}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(DashboardConstants.FETCH_DASHBOARD, getDashboardDetails);
}
