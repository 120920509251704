import React from "react";
import {Card, Steps} from "antd";
import WarningIcon from "../../../assets/icon/Warning.svg";
import "./AuditBox.scss";
import BlueButton from "../BlueButton";
import moment from "moment";
import {useHistory} from "react-router-dom";

const AuditBox = ({
  auditList,
  fromLocation,
  fromDashboard,
  setCurrentTab,
}) => {
  let history = useHistory();
  const { Step } = Steps;
  const IconFun = (item) => (
    <div
      align="center"
      style={{
        backgroundColor: `${
          item?.machineStatusDescription === "#F5A623" ? "green" : "#B52D2D"
        }`,
        borderRadius: "50%",
        width: "38px",
        height: "38px",
      }}
    >
      <img
        width="18px"
        height="18px"
        src={WarningIcon}
        alt=""
        style={{ marginBottom: "5px" }}
      />
    </div>
  );
  return (
    <Card title="Kiosks Audit Log" className="card AuditBox">
      <Steps direction="vertical" current={10}>
        {(auditList || []).map(
          (item, index) =>
            index < 5 && (
              <Step
                key={index}
                icon={IconFun(item)}
                title={
                  <div>
                    <div className="machine-status">
                      <h5>{fromDashboard ? item.chainName : <div></div>}</h5>
                    </div>
                    <div className="store-address fw-500 mt-14">
                      <p style={{ lineHeight: "15.5px", fontSize: "13px" }}>
                        {fromLocation ? (
                          <div
                            className={"mt-3 fs-17"}
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            {item?.issueDescription}
                          </div>
                        ) : (
                          <span>
                            <b> {item.machineStatusDescription}</b>
                            {" from " +
                              item.address +
                              " " +
                              item.city +
                              " " +
                              item.stateCode +
                              " " +
                              item.zip}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                }
                description={
                  <div style={{ fontSize: "14px" }}>
                    {moment(item.createdOn).format("MM/DD/YYYY - hh:mm A")}
                  </div>
                }
              />
            )
        )}
      </Steps>
      <div className="mt-13" align="center">
        {(auditList || []).length ? (
          <>
            <BlueButton onClick={() => setCurrentTab(2)}>
              {fromDashboard ? "View Audit" : "View Issues"}
            </BlueButton>
          </>
        ) : (
          <div style={{ marginTop: "50%" }}>
            No Audit Logs are available for this machine.
          </div>
        )}
      </div>
    </Card>
  );
};

export default AuditBox;
