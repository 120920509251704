// eslint-disable-next-line no-unused-vars
import { ExportToCsv } from "export-to-csv";
import { isArray } from "lodash";

export default function exportCSVFile(items, filename) {
  let headers = [];
  // eslint-disable-next-line array-callback-return
  Object.keys(isArray(items) ? items[0] : items).map((item) => {
    headers = [
      ...headers,
      item.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
        return str.toUpperCase();
      }), //eslint-disable-line
    ];
  });
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    filename,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers,
  };
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(items?.results || items);
}
