import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { DatePicker } from "antd";
import moment from "moment";
import ExportButton from "../../common/components/ExportButton";
import SearchBar from "../../common/components/SearchBar";
import CustomInput from "../../common/components/CustomInput";
import CustomSelect from "../../common/components/CustomSelect";
import CustomTable from "../../common/components/CustomTable";
import Header from "../../common/components/Header";
import { openNotification, sortArray } from "../../utils/common";
import Loading from "../../common/components/Loading";
import {
  exportsEntries,
  fetchEntries,
} from "../../redux/modules/Entries/entriesActions";
import {
  fetchProducts,
  fetchStates,
} from "../../redux/modules/Common/commonActions";

import { formatPhoneNumber } from "../../utils/common";
import exportCSVFile from "../../utils/JSON2csv";
import { config } from "../../utils/config";
import "./Entries.scss";

function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a style={{ color: "#01b2aa" }}>Previous</a>;
  }
  if (type === "next") {
    return <a style={{ color: "#01b2aa" }}>Next</a>;
  }
  return originalElement;
}

const Entries = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [reqBody, setReqBody] = useState({
    // city: "",
    // gender: null,
    keyword: "",
    productId: null,
    sortBy: "",
    storeName: "",
    stateIds: [],
    dmaZip: "",
    productNames: [],
    pageSize: 10,
    startDate: "",
    endDate: "",
    pageIndex: 0,
    isAscending: false,
    timeZoneName: global?.timeZoneName || config?.TIMEZONE,
  });

  const entriesDashboardColumns = [
    {
      title: "Comms?",
      dataIndex: "isAllowNewsletter",
      key: "isAllowNewsletter",
      render: (text) => (text ? 'Yes' : 'No'),
      sorter: (a, b) => sortArray(a, b, "isAllowNewsletter"),
    },
    {
      title: "Date",
      dataIndex: "formattedDate",
      key: "formattedDate",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "formattedDate"),
    },
    {
      title: "Name",
      dataIndex: "customerFirstName",
      key: "customerFirstName",
      render: (text, data) => data?.customerFirstName + " " + data?.customerLastName,
      sorter: (a, b) => sortArray(a, b, "customerFirstName"),
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      render: (text) => <span style={{ color: "#0e4c8c" }}>{text}</span>,
      sorter: (a, b) => sortArray(a, b, "customerEmail"),
    },
    {
      title: "Phone",
      dataIndex: "customerPhone",
      render: (text) => formatPhoneNumber(text),
      key: "customerPhone",
      sorter: () => {},
    },
    {
      title: "Store",
      dataIndex: "storeName",
      key: "storeName",
      render: (text) => <span style={{ color: "#0e4c8c" }}>{text}</span>,
      sorter: (a, b) => sortArray(a, b, "storeName"),
    },
    {
      title: "Kiosk ID",
      dataIndex: "kioskId",
      key: "kioskId",
      sorter: () => {},
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "city"),
    },
    {
      title: "State",
      dataIndex: "stateCode",
      key: "stateCode",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "stateCode"),
    },
    {
      title: "Zip Code",
      dataIndex: "customerZip",
      key: "customerZip",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "customerZip"),
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "productName"),
    }, // eslint-disable-line
    // {
    //   title: "Message",
    //   dataIndex: "labelMessage",
    //   key: "labelMessage",
    //   render: (text) => text,
    //   sorter: (a, b) => sortArray(a, b, "labelMessage"),
    // },
  ];
  const [data, setData] = useState([]); // eslint-disable-line
  const [products, setProducts] = useState([]); // eslint-disable-line
  const [totalRecords, setTotalRecords] = useState(0); // eslint-disable-line
  const [states, setStates] = useState([]);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    getEntriesList();
  }, [reqBody]);

  useEffect(() => {
    getStateList();
    getStatesList();
  }, []);

  const handleSearchInputs = (event) => {
    setReqBody({ ...reqBody, [event.target.name]: event.target.value });
  };

  const handleChange = (pagination, filters, sorter) => {
    if (
      reqBody.pageIndex !== pagination.current - 1 ||
      reqBody.sortBy !== sorter.field ||
      reqBody.isAscending !== (sorter.order === "ascend") ||
      sorter.order === undefined
    )
      setReqBody({
        ...reqBody,
        pageIndex:
          pagination.current >= 0 ? pagination.current - 1 : reqBody.pageIndex,
        sortBy: sorter.order === undefined ? "" : sorter.field,
        isAscending: sorter.order === "ascend",
      });
  };
  const handlePageSize = (e) => {
    // eslint-disable-line
    setReqBody({ ...reqBody, pageSize: e.target.value, pageIndex: 0 });
  };

  const handleOnChange = (e) => {
    setReqBody({ ...reqBody, keyword: e.target.value });
  };

  const handleStateChange = (e) => {
    setReqBody({
      ...reqBody,
      stateIds: e.target.value === undefined ? [] : [e.target.value],
    });
  };

  const handleProductChange = (e) => {
    setReqBody({
      ...reqBody,
      productNames: e.target.value === undefined ? [] : [e.target.value],
    });
  };


  const handleInputs = (event) => {
    setReqBody({ ...reqBody, [event.target.name]: event.target.value });
  };

  const ItemsOnPage = () => {
    return (
      <CustomSelect
        width="73px"
        notAllowClear
        value={reqBody.pageSize}
        onChange={handlePageSize}
        options={[10, 20, 30]}
      />
    );
  };
  const getEntriesList = () => {
    const onFail = () => {
      console.log("Error");
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setTotalRecords(res?.totalRecords || 0);
      _.forEach(res?.results, (item) => {
        item.key = item.id;
      });
      setData(res?.results);
    };
    dispatch(
      fetchEntries({
        data: reqBody,
        onSuccess,
        onFail,
      })
    );
  };

  const exportsEntriesList = () => {
    const onFail = (e) => {
      console.log(e);
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      if (res?.results?.length) {
        const finalArray = res?.results?.map((i) => ({
          Date: i?.lastUpdatedOn || "-",
          CustomerFirstName: i?.customerFirstName || "-",
          CustomerEmail: i?.customerEmail || "-",
          CustomerPhone: i?.customerPhone || "-",
          StoreName: i?.storeName || "-",
          KioskId: i?.kioskId || "-",
          City: i?.city || "-",
          StateCode: i?.stateCode || "-",
          CustomerZip: i?.customerZip || "-",
          ProductName: i?.productName || "-",
          isAllowNewsletter:i?.isAllowNewsletter ? "Yes" : "No"
        }));
        exportCSVFile(finalArray,"entries");
      }
    };
    // entries;
    dispatch(
      exportsEntries({
        data: reqBody,
        onSuccess,
        onFail,
      })
    );
  };

  const getStateList = () => {
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setProducts(res);
    };
    dispatch(
      fetchProducts({
        onSuccess,
        onFail,
      })
    );
  };
  const getStatesList = () => {
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setStates(res);
    };
    dispatch(
      fetchStates({
        onSuccess,
        onFail,
      })
    );
  };
  const loading = useSelector(
    (state) =>
      state.entries.fetchEntriesLoading || state.common.fetchStatesLoading
  );
  return (
    <>
      {loading && <Loading />}
      <Header>Entries</Header>
      <div className="card box-margin-main">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <SearchBar onChange={handleOnChange} />
          </div>
          <div className="col-md-6 col-sm-12">
            <div align="right">
              <ExportButton onClick={exportsEntriesList} />
            </div>
          </div>
        </div>
        <div className="row">
          {/*<div className="col-md-6 col-lg-3 mt-20 col-sm-12 d-flex">*/}
          {/*  <div className="mt-6 mr-18">Gender:</div>*/}
          {/*  <CustomSelect*/}
          {/*    width="191px"*/}
          {/*    name="gender"*/}
          {/*    placeholder="Select"*/}
          {/*    value={reqBody.gender}*/}
          {/*    onChange={handleInputs}*/}
          {/*    options={["Male", "Female"]}*/}
          {/*  />*/}
          {/*</div>*/}
          {/* <div className="col-md-6 col-lg-4 col-xl-2 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 entries-filter">City:</div>
            <span>
              <CustomInput
                width="191px"
                size="small"
                name="city"
                value={reqBody.city}
                onChange={handleInputs}
              />
            </span>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-2 mt-20 col-sm-12 d-flex ">
            <div className="mt-6 mr-18 entries-filter">State:</div>
            <CustomSelect
              labelKey="stateName"
              width={"191px"}
              placeholder="Select"
              onChange={handleStateChange}
              options={states}
            />
          </div> */}
          <div className="col-md-6 col-lg-4 col-xl-2 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 entries-filter">Store:</div>
            <span>
              <CustomInput
                width="160px"
                size="small"
                name="storeName"
                value={reqBody.storeName}
                onChange={handleInputs}
              />
            </span>
          </div>

          <div className="col-md-6 col-lg-4 col-xl-3 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 entries-filter">Date:</div>
            <RangePicker
              allowClear={true}
              onChange={(e) => {
                if (e?.length) {
                  setReqBody({
                    ...reqBody,
                    startDate: e[0].format("YYYY-MM-DD"),
                    endDate: e[1].format("YYYY-MM-DD"),
                  });
                } else {
                  setReqBody({
                    ...reqBody,
                    startDate: "",
                    endDate: "",
                  });
                }
              }}
              value={
                reqBody?.startDate
                  ? reqBody?.endDate
                    ? [
                        moment(reqBody?.startDate, "YYYY/MM/DD"),
                        moment(reqBody?.endDate, "YYYY/MM/DD"),
                      ]
                    : [moment(reqBody?.startDate, "YYYY/MM/DD")]
                  : null
              }
            />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 mt-20 col-sm-12 d-flex ">
            <div className="mt-6 mr-18 entries-filter">Product:</div>
            <CustomSelect
              labelKey="productName"
              width={"270px"}
              value={reqBody.productNames}
              name={"productName"}
              placeholder="Select"
              onChange={handleProductChange}
              options={products}
            />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-2 mt-20 col-sm-12 d-flex">
            <div className="mt-6 mr-18 entries-filter">Zip Code:</div>
            <span>
              <CustomInput
                width="140px"
                size="small"
                name="dmaZip"
                value={reqBody.dmaZip}
                onChange={handleInputs}
              />
            </span>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-2 mt-20 col-sm-12 d-flex w-100 justify-content-start">
            <div className="mt-6 entries-filter">Show &nbsp;</div>
            {<ItemsOnPage />}
            <div className="mt-6 entries-filter">&nbsp; items</div>
          </div>
        </div>
        <div className="mt-30">
          <CustomTable
            columns={entriesDashboardColumns}
            // columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={
              totalRecords > reqBody.pageSize
                ? {
                    itemRender,
                    pageSize: reqBody.pageSize,
                    total: totalRecords,
                    current: reqBody.pageIndex + 1,
                    position: ["bottomCenter"],
                  }
                : false
            }
          />
        </div>
      </div>
    </>
  );
};

export default Entries;
