import { handleActions } from "redux-actions";
import { initialAuditLogState } from "./initialAuditLogState";
import { AuditLogConstants } from "./auditLogConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const auditLogReducer = handleActions(
  {
    [requestSuccess(AuditLogConstants.FETCH_AUDIT_LOG)]: state => ({
      ...state,
      fetchAuditLogLoading: false,
      fetchAuditLogFailure: false,
      fetchAuditLogLoaded: true
    }),
    [requestPending(AuditLogConstants.FETCH_AUDIT_LOG)]: state => ({
      ...state,
      fetchAuditLogLoading: true,
      fetchAuditLogLoaded: false,
      fetchUserDetailsFailure: false
    }),
    [requestFail(AuditLogConstants.FETCH_AUDIT_LOG)]: state => ({
      ...state,
      fetchAuditLogLoading: false,
      fetchAuditLogLoaded: true,
      fetchAuditLogFailure: true
    }),
    [requestSuccess(AuditLogConstants.CREATE_AUDIT_LOG)]: state => ({
      ...state,
      createAuditLogLoading: false,
      createAuditLogFailure: false,
      createAuditLogLoaded: true
    }),
    [requestPending(AuditLogConstants.CREATE_AUDIT_LOG)]: state => ({
      ...state,
      createAuditLogLoading: true,
      createAuditLogLoaded: false,
      createUserDetailsFailure: false
    }),
    [requestFail(AuditLogConstants.CREATE_AUDIT_LOG)]: state => ({
      ...state,
      createAuditLogLoading: false,
      createAuditLogLoaded: true,
      createAuditLogFailure: true
    })
  },
  initialAuditLogState()
);
