import React from 'react';

const printerSvg = ({ icon_color }) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1024 1024'
      x='0px'
      y='0px'
    >
      <path
        d='M917.3,256H106.7C46.9,256,0,302.9,0,362.7v256c0,59.7,46.9,106.7,106.7,106.7h106.7v277.3c0,12.8,8.5,21.3,21.3,21.3h554.7
	c12.8,0,21.3-8.5,21.3-21.3V725.3h106.7c59.7,0,106.7-46.9,106.7-106.7v-256C1024,302.9,977.1,256,917.3,256z M768,981.3H256v-384
	h512V981.3z M981.3,618.7c0,34.1-29.9,64-64,64H810.7V576c0-12.8-8.5-21.3-21.3-21.3H234.7c-12.8,0-21.3,8.5-21.3,21.3v106.7H106.7
	c-34.1,0-64-29.9-64-64v-256c0-34.1,29.9-64,64-64h810.7c34.1,0,64,29.9,64,64V618.7z M234.7,213.3c12.8,0,21.3-8.5,21.3-21.3V42.7
	h384v106.7c0,12.8,8.5,21.3,21.3,21.3H768V192c0,12.8,8.5,21.3,21.3,21.3c12.8,0,21.3-8.5,21.3-21.3v-42.7c0-4.3,0-4.3,0-8.5
	c0-4.3-4.3-4.3-4.3-8.5l-128-128c0,0-4.3-4.3-8.5-4.3s-4.3,0-8.5,0H234.7c-12.8,0-21.3,8.5-21.3,21.3V192
	C213.3,204.8,221.9,213.3,234.7,213.3z M682.7,72.5l55.5,55.5h-55.5V72.5z M149.3,341.3c-34.1,0-64,29.9-64,64s29.9,64,64,64
	s64-29.9,64-64S183.5,341.3,149.3,341.3z M149.3,426.7c-12.8,0-21.3-8.5-21.3-21.3c0-12.8,8.5-21.3,21.3-21.3
	c12.8,0,21.3,8.5,21.3,21.3C170.7,418.1,162.1,426.7,149.3,426.7z M631.5,686.9c-4.3-8.5-8.5-12.8-17.1-12.8s-17.1,4.3-21.3,8.5
	l-85.3,128l-85.3-72.5c-4.3-4.3-12.8-4.3-17.1-4.3c-4.3,0-12.8,4.3-12.8,12.8l-81.1,157.9c-4.3,8.5-4.3,12.8,0,21.3
	c0,8.5,8.5,12.8,12.8,12.8h375.5c8.5,0,12.8-4.3,17.1-8.5c4.3-4.3,4.3-12.8,0-21.3L631.5,686.9z M358.4,896l55.5-106.7l85.3,68.3
	c4.3,4.3,12.8,4.3,17.1,4.3s12.8-4.3,12.8-8.5l76.8-115.2l64,153.6H358.4V896z M328.5,674.1c0-25.6,17.1-42.7,42.7-42.7
	c25.6,0,42.7,17.1,42.7,42.7s-17.1,42.7-42.7,42.7C345.6,716.8,328.5,695.5,328.5,674.1z'
        fill={icon_color}
      />
    </svg>
  );
};

export default printerSvg;
