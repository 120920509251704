import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { AuditLogConstants } from "./auditLogConstants";

function* getAuditLog(action) {
  yield call(
    request({
      type: AuditLogConstants.FETCH_AUDIT_LOG,
      method: "POST",
      url: `AuditLog/SearchAuditLog`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* createAuditLog(action) {
  yield call(
    request({
      type: AuditLogConstants.CREATE_AUDIT_LOG,
      method: "POST",
      url: `AuditLog`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(AuditLogConstants.FETCH_AUDIT_LOG, getAuditLog);
  yield takeLatest(AuditLogConstants.CREATE_AUDIT_LOG, createAuditLog);
}
