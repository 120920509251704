import { createAction } from "redux-actions";
import { UserDetailsConstants } from "./userDetailsConstants";

export const fetchUserDetails = createAction(
  UserDetailsConstants.FETCH_USER_DETAILS
);
export const updateUserDetails = createAction(
  UserDetailsConstants.UPDATE_USER_DETAILS
);
export const updateUserStatus = createAction(
  UserDetailsConstants.UPDATE_USER_STATUS
);
export const createUser = createAction(UserDetailsConstants.CREATE_USER);
export const updateUserImage = createAction(UserDetailsConstants.UPDATE_IMAGE);
export const deleteUserImage = createAction(UserDetailsConstants.DELETE_IMAGE);
export const userLogin = createAction(UserDetailsConstants.GET_LOGIN_DETAILS);
export const userLogout = createAction(UserDetailsConstants.USER_LOGOUT);
export const refreshToken = createAction(UserDetailsConstants.REFRESH_TOKEN);
export const forgotPassword = createAction(
  UserDetailsConstants.FORGOT_PASSWORD
);
export const resetPassword = createAction(UserDetailsConstants.RESET_PASSWORD);
export const updatePassword = createAction(UserDetailsConstants.UPDATED_PASSWORD);
export const resetLoginPassword = createAction(
  UserDetailsConstants.RESET_LOGIN_PASSWORD
);
