import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchDashboard } from "../../redux/modules/Dashboard/dashboardActions";
import {
  fetchStatistics,
  fetchEntries,
} from "../../redux/modules/Entries/entriesActions";
import { fetchAuditLog } from "../../redux/modules/AuditLog/auditLogActions";
import {
  formatPhoneNumber,
  openNotification,
  sortArray,
} from "../../utils/common";
import Header from "../../common/components/Header";
import Overview from "../LocationDetails/components/Overview";
import _ from "lodash";
import moment from "moment";
import CustomTable from "../../common/components/CustomTable";
import BlueButton from "../../common/components/BlueButton";
import Loading from "../../common/components/Loading";
import DashboardModal from "./components/DashboardModal";
import { config } from "../../utils/config";
import { fetchStores } from "../../redux/modules/Stores/storesActions";
import "./Dashboard.scss";

const Dashboard = (props) => {
  // const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [statisticsList, setStatisticsList] = useState([]);
  const [auditList, setAuditList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [kioskDetails, setDashboardDetails] = useState({});
  const [fiveEntriesData, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    getDashboardInfo();
    getStatisticList();
    getAuditList();
    getStoreList();
    getEntriesList();
  }, []);
  // const showModal = () => {
  //   setVisibleModal(true);
  // };
  const handleModal = () => {
    setVisibleModal(!visibleModal);
  };
  // const handleOk = () => {
  //   setVisibleModal(false);
  // };

  // const handleCancel = () => {
  //   setVisibleModal(false);
  // };

  const entriesDashboardColumns = [
    {
      title: "Comms?",
      dataIndex: "isAllowNewsletter",
      key: "isAllowNewsletter",
      render: (text) => (text ? 'Yes' : 'No'),
      sorter: (a, b) => sortArray(a, b, "isAllowNewsletter"),
    },
    {
      title: "Date",
      dataIndex: "formattedDate",
      key: "formattedDate",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "formattedDate"),
    },
    {
      title: "Name",
      dataIndex: "customerFirstName",
      key: "customerFirstName",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "customerFirstName"),
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      render: (text) => <span style={{ color: "#0E4C8C" }}>{text}</span>,
      sorter: (a, b) => sortArray(a, b, "customerEmail"),
    },
    {
      title: "Phone",
      dataIndex: "customerPhone",
      render: (text) => formatPhoneNumber(text),
      sorter: () => {},
    },
    {
      title: "Store",
      dataIndex: "storeName",
      key: "storeName",
      render: (text) => <span style={{ color: "#0e4c8c" }}>{text}</span>,
      sorter: (a, b) => sortArray(a, b, "storeName"),
    },
    {
      title: "Kiosk ID",
      dataIndex: "kioskId",
      key: "kioskId",
      sorter: () => {},
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "city"),
    },
    {
      title: "State",
      dataIndex: "stateCode",
      key: "stateCode",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "stateCode"),
    },
    {
      title: "Zip Code",
      dataIndex: "customerZip",
      key: "customerZip",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "customerZip"),
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      render: (text) => text,
      sorter: (a, b) => sortArray(a, b, "productName"),
    }, // eslint-disable-line
    // {
    //   title: "Message",
    //   dataIndex: "labelMessage",
    //   key: "labelMessage",
    //   render: (text) => text,
    //   sorter: (a, b) => sortArray(a, b, "labelMessage"),
    // },
  ];

  const [reqBody] = useState({
    keyword: "",
    gender: null,
    productId: null,
    store: "",
    date: "",
    machineName: "",
    pageSize: 5,
    pageIndex: 0,
    timeZoneName: global?.timeZoneName || config?.TIMEZONE,
  });

  const getDashboardInfo = () => {
    const onSuccess = (response) => {
      setDashboardDetails(response);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(
      fetchDashboard({
        data: reqBody?.timeZoneName,
        onSuccess,
        onFail,
      })
    );
  };

  const getStoreList = () => {
    const onSuccess = (response) => {
      _.forEach(response, (item) => {
        item.id = item.storeId;
        item.name = item.storeName;
      });
      setStoreList(response || []);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(fetchStores({ onSuccess, onFail }));
  };

  const getAuditList = () => {
    const onSuccess = (response) => {
      setAuditList(response?.results || []);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    dispatch(
      fetchAuditLog({
        data: {
          brandIds: [],
          pageSize: 6,
          pageIndex: 0,
          isAscending: false,
          timeZoneName: global?.timeZoneName || config?.TIMEZONE,
        },
        onSuccess,
        onFail,
      })
    );
  };

  const getStatisticList = (yearValue) => {
    const defaultYear = moment().years();
    const onSuccess = (response) => {
      _.forEach(response, (item) => {
        item.color = "#0e4c8c";
        item.month = item.month.toUpperCase();
      });
      setStatisticsList(response || []);
    };
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };

    const data = { Year: moment(yearValue).years() || defaultYear, KioskId: 0 };

    dispatch(
      fetchStatistics({
        data: data,
        onSuccess,
        onFail,
      })
    );
  };

  const getEntriesList = () => {
    const onFail = () => {
      openNotification({
        message: "Error",
        description: "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      setTotalRecords(res?.totalRecords || 0);
      _.forEach(res?.results, (item) => {
        item.key = item.id;
      });
      setData(res?.results);
    };
    dispatch(
      fetchEntries({
        data: reqBody,
        onSuccess,
        onFail,
      })
    );
  };
  const loading = useSelector((state) => state.dashboard.fetchStatesLoading);
  const loading2 = useSelector(
    (state) =>
      state.location.fetchKioskByIdLoading ||
      state.location.fetchKioskDashboardLoading ||
      state.dashboard.fetchDashboardLoading ||
      state.entries.fetchStatisticsLoading ||
      state.auditLog.fetchAuditLogLoading ||
      state.store.fetchStoresLoading
  );
  return (
    <>
      {/* {window.screen.width > 900 ? "dsds" : "dsds"} */}
      {visibleModal && (
        <DashboardModal
          // errorMsg={errorMsg}
          // addUser={addUser}
          rolesList={roles}
          visible={visibleModal}
          onClose={handleModal}
        />
      )}
      {loading2 && <Loading />}
      <Header>Dashboard</Header>
      <div className="dashboard-details">
        <div className="position-relative" style={{ position: "relative" }}>
          <div className="update-div row">
            <Overview
              storeList={storeList}
              getStatisticList={getStatisticList}
              statisticsList={statisticsList}
              auditList={auditList}
              kioskDetails={kioskDetails}
              fromDashboard={true}
            />
            <div className="card five-entries">
              <span>
                <p className="fw-500 fs-16" onClick={handleModal}>
                  Last 5 Entries
                </p>
              </span>
              <div className="mt-30">
                <CustomTable
                  columns={entriesDashboardColumns}
                  loading={loading}
                  dataSource={fiveEntriesData}
                  onChange={false}
                  pagination={false}
                />
              </div>
              <div className="mt-24" align="center">
                {totalRecords ? (
                  <BlueButton onClick={() => history.push("/entries")}>
                    View Sale Entries
                  </BlueButton>
                ) : (
                  <div style={{ marginTop: "50%" }}>
                    No Entries are available currently.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
