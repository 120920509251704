import React from "react";
import {Checkbox} from "antd";
import "./CheckBox.scss";

const CheckBox = props => {
    const {label, labelClass, value, onChange, name, checked} = props;
    return (
        <div className="checkBox">
            <Checkbox name={name} checked={checked} value={value} onChange={onChange}/>
            <div className={`label-div ${labelClass}`}>{label}</div>
            {/*<span className="text-danger">{error}</span>*/}
        </div>
    );
};
export default CheckBox;
