import React, { useEffect } from "react";
import { Select } from "antd";
import "./CustomSelect.scss";

const CustomSelect = (props) => {
  const {
    label,
    value,
    width,
    onChange,
    prefix,
    className,
    placeholder,
    name,
    labelKey,
    showSearch,
    options,
    error,
    notAllowClear,
    disabled,
      mode,
    ColorStyle,
  } = props;

  useEffect(() => {
    let element = document.getElementsByClassName("ant-select-selection-item");
    if (element[0]) element[0].title = "";
    if (element[1]) element[1].title = "";
  }, []);
  return (
    <div style={{ maxWidth: width }} className="custom-select-input">
      {label && (
        <div className="label" style={ColorStyle}>
          {label}
        </div>
      )}
      <Select
          showSearch={showSearch}
          mode={mode}
          disabled={disabled}
          allowClear={!notAllowClear}
          showArrow={true}
          className={className}
          prefix={prefix}
          maxTagCount={"responsive"}
          name={name}
          value={value}
          filterOption={showSearch ? (input, option) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 : ()=>{}}
          filterSort={showSearch ? (optionA, optionB) => optionA?.children?.toLowerCase()?.localeCompare(optionB.children.toLowerCase()) : ()=>{}}
          placeholder={placeholder}
          onChange={(e, e2) => {
            onChange && onChange({target: {value: e, name, value2: e2}});
          }}
      >
        {(options?.length > 0 ? options : []).map((option, index) => {
          return (
            <Select.Option
              value={
                option?.timeZoneList ||
                option?.storeId ||
                option?.name ||
                option?.timeZoneName ||
                option?.productName ||
                option?.productId ||
                option?.brandId ||
                option?.id ||
                option?.stateId ||
                option?.value ||
                option ||
                index
              }
              key={index}
            >
              {option.label ||
                option?.name ||
                option[labelKey] ||
                option ||
                "option"}
            </Select.Option>
          );
        })}
      </Select>
      <span className="text-danger">{error}</span>
    </div>
  );
};
export default CustomSelect;
