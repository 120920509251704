import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import { authRoutes, unAuthRoutes } from "../routes";
import { fetchUserDetails } from "../redux/modules/userDetails/userDetailsActions";
import { Footer } from "../common/components/Footer";
import Sidebar from "../common/components/Sidebar";
import { matchPath } from "react-router";
import { fetchToken, getToken } from "../utils/common";
import { useHistory } from "react-router-dom";

const LoggedInRoutes = (user) =>
  authRoutes.map((r, i) => (
    <Route
      key={i}
      path={r.path}
      exact={r.exact}
      render={(props) => <r.main {...props} user={user || {}} />}
    />
  ));

const LoggedOutRoutes = unAuthRoutes.map((r, i) => (
  <Route
    key={i}
    path={r.path}
    exact={r.exact}
    render={(props) => <r.main {...props} />}
  />
));

const Main = React.memo((props) => {
  // eslint-disable-line
  const isLoggedIn = getToken();
  let history = useHistory();
  // const fetchUserDetails = () => {
  //   const onSuccess = () => {};
  //   const onFail = () => {};
  //   getUserDetails({ onSuccess, onFail });
  // };
  // fetchUserDetails();

  useEffect(() => {
    document.title =
      window.location.pathname === "/"
        ? "Ghirardelli-Admin"
        : (isLoggedIn ? authRoutes : unAuthRoutes).find(
            (i) => matchPath(history.location.pathname, i?.url)?.isExact
          )?.title;
    // document.title = (isLoggedIn ? authRoutes : unAuthRoutes).find(i => matchPath(history.location.pathname, i?.url)?.isExact)?.title;
  }, [history.location.pathname]);
  return (
    <>
      {isLoggedIn ? (
        <div className="app">
          <Sidebar {...props} />
          <main
            className="main"
            style={{
              paddingBottom: "75px",
              marginBottom: "75px",
              position: "relative",
              height: "100%",
            }}
          >
            <Switch>{LoggedInRoutes({})}</Switch>
            <Footer textAlign="left" />
          </main>
        </div>
      ) : (
        <main
          style={
            {
              // paddingBottom: "75px",
            }
          }
        >
          <Switch>{LoggedOutRoutes}</Switch>
          <Footer />
        </main>
      )}
    </>
  );
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: (payload) => dispatch(fetchUserDetails(payload)), // eslint-disable-line
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Main));
// export default Main;

// api for display locations
