const keyMirror = require("keymirror");

export const LocationConstants = keyMirror({
  FETCH_LOCATIONS: undefined,
  EXPORTS_MACHINES: undefined,
  DEACTIVATE_MACHINES: undefined,
  FETCH_MACHINE_BY_ID: undefined,
  FETCH_MACHINE_DASHBOARD: undefined,
  UPDATE_GENERAL_DETAILS: undefined,
  FETCH_MACHINE: undefined
});
