import { handleActions } from "redux-actions";
import { initialAccountState } from "./initialAccountState";
import { AccountConstants } from "./accountConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const accountReducer = handleActions(
  {
    [requestSuccess(AccountConstants.FETCH_ACCOUNTS)]: (
      state
      // action
    ) => ({
      ...state,
      fetchAccountsLoading: false,
      fetchAccountsFailure: false,
      fetchAccountsLoaded: true
    }),
    [requestPending(AccountConstants.FETCH_ACCOUNTS)]: state => ({
      ...state,
      fetchAccountsLoading: true,
      fetchAccountsLoaded: false,
      fetchUserDetailsFailure: false
    }),
    [requestFail(AccountConstants.FETCH_ACCOUNTS)]: state => ({
      ...state,
      fetchAccountsLoading: false,
      fetchAccountsLoaded: true,
      fetchAccountsFailure: true
    }),
    [requestSuccess(AccountConstants.FETCH_ROLES)]: (
      state
      // action
    ) => ({
      ...state,
      fetchRolesLoading: false,
      fetchRolesFailure: false,
      fetchRolesLoaded: true
    }),
    [requestPending(AccountConstants.FETCH_ROLES)]: state => ({
      ...state,
      fetchRolesLoading: true,
      fetchRolesFailure: false,
      fetchRolesLoaded: false
    }),
    [requestFail(AccountConstants.FETCH_ROLES)]: state => ({
      ...state,
      fetchRolesLoading: false,
      fetchRolesFailure: true,
      fetchRolesLoaded: true
    })
  },
  initialAccountState()
);
