import React, { useEffect, useState } from "react";
import CustomModal from "../../../common/components/CustomModal";
import CustomInput from "../../../common/components/CustomInput";
import CustomSelect from "../../../common/components/CustomSelect";
import { fetchTimeZones } from "../../../redux/modules/Common/commonActions";
import UploadIcon from "../../../assets/icon/Upload.svg";
import { ButtonWithIcon } from "../../../common/components/ButtonWithIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  emailValidation,
  enforceFormat,
  formatToPhone,
  openNotification,
  phoneNumberValidation,
} from "../../../utils/common";

const AddAccountModal = ({
  visible,
  onClose,
  rolesList,
  addUser,
  errorMsg,
}) => {
  const dispatch = useDispatch();
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    roleName: "",
    timeZoneName: [],
  }); // eslint-disable-line
  const [imageObj, setImageObj] = useState();
  const [error, setError] = useState(false);

  const getTimeZones = () => {
    //eslint-disable-line
    const onFail = (res) => {
      openNotification({
        message: "Error",
        description:
          res?.date?.message || "Error while processing your request!",
        error: true,
      });
    };
    const onSuccess = (res) => {
      //eslint-disable-line
      setTimeZoneList(res);
    };
    dispatch(
      fetchTimeZones({
        data: {},
        onSuccess,
        onFail,
      })
    );
  };

  useEffect(() => {
    getTimeZones();
  }, []);

  const handelState = (e) => {
    let { name, value } = e.target;
    if (name === "phoneNumber" && value.length > 14)
      value = value.substring(0, 14);
    setState({ ...state, [name]: value });
  };
  const onSubmitForm = () => {
    if (
      state.firstName &&
      state.lastName &&
      emailValidation(state.email) &&
      phoneNumberValidation(state.phoneNumber) &&
      state.roleName &&
      state.timeZoneName
      // imageObj?.target?.files?.length
    )
      addUser(state, imageObj);
    else setError(true);
  };
  const loading = useSelector(
    (state) =>
      state.userDetails.createUserLoading ||
      state.userDetails.updateUserImageLoading
  );
  useEffect(() => {
    const inputElement = document.getElementById("phoneNumberAccount");
    inputElement.addEventListener("keydown", enforceFormat);
    inputElement.addEventListener("keyup", formatToPhone);
  }, []);

  const ColorStyle = {
    color: "#545454",
    lineHeight: "20px",
    marginBottom: "11px",
    fontWeight: "400",
  };
  return (
    <CustomModal
      loading={loading}
      onClickSubmit={onSubmitForm}
      btnname="Add Administrators"
      title="Add Administrators"
      visible={visible}
      onClose={onClose}
    >
      <div className="row add-user-style">
        <div className="col-lg-12 col-md-12 col-xs-12 mt-20">
          {errorMsg && (
            <span className={"fs-14"} style={{ color: "red" }}>
              {errorMsg}
            </span>
          )}
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12">
          <CustomInput
            onChange={handelState}
            value={state.firstName}
            name={"firstName"}
            width="280px"
            label="First Name"
            placeholder="Enter First Name"
            error={
              error && !state.firstName
                ? "Please enter valid first name"
                : false
            }
          />
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12">
          <CustomInput
            onChange={handelState}
            value={state.lastName}
            name={"lastName"}
            width="280px"
            label="Last Name"
            placeholder="Enter Last Name"
            error={
              error && !state.lastName ? "Please enter valid last name" : false
            }
          />
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12 mt-20">
          <CustomInput
            onChange={handelState}
            value={state.phoneNumber}
            id={"phoneNumberAccount"}
            name={"phoneNumber"}
            width="280px"
            label="Phone Number"
            placeholder="Enter Phone Number"
            error={
              error && !phoneNumberValidation(state.phoneNumber)
                ? "Please enter valid 10 digit phone number"
                : false
            }
          />
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12 mt-20">
          <CustomInput
            onChange={handelState}
            value={state.email}
            name={"email"}
            width="280px"
            label="Email"
            placeholder="Enter Email"
            error={
              error && !emailValidation(state?.email)
                ? "Please enter valid email"
                : false
            }
          />
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12 mt-20">
          <CustomSelect
            width="280px"
            showSearch
            label="Time Zone"
            labelKey={"displayName"}
            ColorStyle={ColorStyle}
            onChange={(e) => {
              setState({
                ...state,
                timeZoneName: e.target?.value,
              });
            }}
            placeholder="Select"
            options={timeZoneList}
            error={
              error && !state.timeZoneList ? "Please select Timezone" : false
            }
          />
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12 mt-20">
          <CustomSelect
            width="280px"
            label="Role"
            ColorStyle={ColorStyle}
            onChange={(e) => {
              setState({ ...state, roleName: e.target?.value2?.children });
            }}
            placeholder="Select"
            options={rolesList}
            error={error && !state.roleName ? "Please select role" : false}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12 mt-20">
          <div className="fw-400" style={ColorStyle}>
            Profile Image
          </div>
          <ButtonWithIcon
            onClick={() => {
              document.getElementById("userImage").click();
            }}
            className="mt-11"
            icon={UploadIcon}
          >
            Upload Picture
          </ButtonWithIcon>
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={(e) => setImageObj(e)}
            id="userImage"
            hidden
          />
          {imageObj?.target?.files?.length
            ? imageObj?.target.files[0].name
            : ""}
          {/*<span className="text-danger">*/}
          {/*  {error && !imageObj?.target?.files?.length*/}
          {/*    ? "Please select image"*/}
          {/*    : false}*/}
          {/*</span>*/}
        </div>
      </div>
    </CustomModal>
  );
};

export default AddAccountModal;
