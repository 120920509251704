import React from "react";
import { Spin } from "antd";
import "./Loading.scss";

const Loading = ({ className, noMarginLeft, mainClass, center }) => {
  return (
    <div className={`loading ${mainClass ? mainClass : ""}`}>
      <div className={`spin-div ml-0 ${noMarginLeft ? "ml-0" : ""}`}>
        <Spin className={`spin ${center ? "no-left" : ""} ${className}`} />
      </div>
    </div>
  );
};

export default Loading;
