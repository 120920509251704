import React from "react";
import { Button } from "antd";
import "./ExportButton.scss";
import ExportIcon from "../../../assets/icon/Export.svg";

const ExportButton = (props) => {
  const { className, onClick } = props;
  return (
    <div className="export-button" align="left">
      <Button
        icon={<img alt="" src={ExportIcon} height="18px" />}
        className={className || ""}
        onClick={onClick || null}
      >
        <span>Export CSV</span>
      </Button>
    </div>
  );
};

export default ExportButton;
