import React, { useEffect, useState } from "react";
import moment from "moment";
import GeneralInfoCard from "../../Profile/components/GeneralInfoCard";
import { Header } from "antd/lib/layout/layout";
import ResetPasswordCard from "../../Profile/components/ResetPasswordCard";
import Loading from "../../../common/components/Loading";
import { formatPhoneNumber } from "../../../utils/common";

const AdministarationProfile = () => {
    const [userInfo, setUserInfo] = React.useState({});
  return (
    <>
      <div className="profile">
        <div className="blue-div fw-300">
          <div className="row details-div">
            <div className="col-sm-6 col-md-3 mt-12 col-lg-4">
              {userInfo?.userName}
              <div className="mt-2">
                {formatPhoneNumber(userInfo?.phoneNumber)}
              </div>
            </div>
            <div className="col-sm-6 col-md-3 mt-12 col-lg-4">
              Last Login:{" "}
              {userInfo?.lastLoginDateTime
                ? moment(userInfo.lastLoginDateTime).format(
                    "MM/DD/YYYY - hh:mm A"
                  )
                : "Never Logged In"}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-30 mb-35">
              <span className="overview-underline fw-300">Overview</span>
            </div>
          </div>
        </div>
        <div className="position-relative" style={{ position: "relative" }}>
          <div className="update-div row">
            <div className="col-lg-6 col-md-12 mb-20">
              <GeneralInfoCard />
            </div>
            <div className="col-lg-6 col-md-12 reset-password-div">
              <ResetPasswordCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdministarationProfile;
