import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { StoresConstants } from "./storesConstants";

function* getStores(action) {
  yield call(
    request({
      type: StoresConstants.FETCH_STORES,
      method: "GET",
      url: `Store`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* getStoreById(action) {
  yield call(
    request({
      type: StoresConstants.FETCH_STORE_BY_ID,
      method: "GET",
      url: `Store`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}
function* updateStoreDetails(action) {
  yield call(
    request({
      type: StoresConstants.UPDATE_STORE_DETAILS,
      method: "PUT",
      url: `Store/UpdateStore`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(StoresConstants.FETCH_STORES, getStores);
  yield takeLatest(StoresConstants.FETCH_STORE_BY_ID, getStoreById);
  yield takeLatest(StoresConstants.UPDATE_STORE_DETAILS, updateStoreDetails);
}
