import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { LocationConstants } from "./locationsConstants";

function* getLocations(action) {
  yield call(
    request({
      type: LocationConstants.FETCH_LOCATIONS,
      method: "POST",
      url: `Kiosk/SearchKiosk`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* exportsMachines(action) {
  yield call(
    request({
      type: LocationConstants.EXPORTS_MACHINES,
      method: "POST",
      url: `Kiosk/ExportKiosk`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* deactivateMachine(action) {
  yield call(
    request({
      type: LocationConstants.DEACTIVATE_MACHINES,
      method: "PUT",
      url: `Kiosk/UpdateKioskConnectivityInfo/${action.payload.data?.id}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* getMachineById(action) {
  yield call(
    request({
      type: LocationConstants.FETCH_MACHINE_BY_ID,
      method: "GET",
      url: `Kiosk/${action.payload.data?.id}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* getMachineDashboard(action) {
  yield call(
    request({
      type: LocationConstants.FETCH_MACHINE_DASHBOARD,
      method: "GET",
      url: `Kiosk/GetCounts?kioskId=${action?.payload?.data?.id}&timeZoneName=${action?.payload?.data?.timeZoneName}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}
function* updateGeneralDetails(action) {
  yield call(
    request({
      type: LocationConstants.UPDATE_GENERAL_DETAILS,
      method: "PUT",
      url: `Kiosk/UpdateKioskGeneralInfo/${action.payload.data?.id}`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(LocationConstants.FETCH_LOCATIONS, getLocations);
  yield takeLatest(LocationConstants.EXPORTS_MACHINES, exportsMachines);
  yield takeLatest(LocationConstants.DEACTIVATE_MACHINES, deactivateMachine);
  yield takeLatest(LocationConstants.FETCH_MACHINE_BY_ID, getMachineById);
  yield takeLatest(
    LocationConstants.FETCH_MACHINE_DASHBOARD,
    getMachineDashboard
  );
  yield takeLatest(
    LocationConstants.UPDATE_GENERAL_DETAILS,
    updateGeneralDetails
  );
}
