export const initialEntriesState = () => ({
  fetchEntriesLoading: false,
  fetchEntriesFailure: false,
  fetchEntriesLoaded: false,
  exportEntriesLoading: false,
  exportEntriesFailure: false,
  exportEntriesLoaded: false,
  fetchStatisticsLoading: false,
  fetchStatisticsFailure: false,
  fetchStatisticsLoaded: false
});
