import React from 'react';

const paperSvg = ({ icon_color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1024 1024'
      x='0px'
      y='0px'
    >
      <path
        d='M167.3,195.2c0,55.8,86.2,91.2,172.4,91.2S512,256,512,195.2C517.1,78.6,167.3,78.6,167.3,195.2z M344.7,235.7
	c-81.1,0-121.7-30.4-121.7-40.6c0-10.1,40.6-40.6,121.7-40.6s121.7,30.4,121.7,40.6C466.4,205.3,425.8,235.7,344.7,235.7z
	 M998.7,149.5H674.2c-40.6-71-172.4-126.7-329.5-126.7C152.1,22.8,0,98.9,0,195.2v633.7c0,96.3,152.1,172.4,344.7,172.4
	c162.2,0,289-50.7,329.5-126.7h324.4c15.2,0,25.3-10.1,25.3-25.3V174.9C1024,159.7,1013.9,149.5,998.7,149.5z M633.7,828.8
	c0,55.8-121.7,121.7-294,121.7s-289-60.8-289-121.7V286.4c60.8,50.7,167.3,81.1,294,81.1s233.2-30.4,294-81.1v542.4H633.7z
	 M344.7,321.9c-172.4,0-294-65.9-294-121.7s121.7-121.7,294-121.7s294,65.9,294,121.7S517.1,321.9,344.7,321.9z M973.3,823.8h-289
	V200.2h289V823.8z'
        fill={icon_color}
      />
    </svg>
  );
};

export default paperSvg;
