import React from "react";
import { DatePicker, Form } from "antd";
export const DataPickerInput = props => {
  const { handleChange,picker,defaultValue,size,placeholder,width, label, error } = props;
  return (
    <Form.Item label={label}>
      <DatePicker style={{ maxWidth: width }} defaultValue={defaultValue} picker={picker} size={size} placeholder={placeholder} onChange={handleChange} />
      <span className="text-danger">{error}</span>
    </Form.Item>
  );
};
