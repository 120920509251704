import React from "react";
import "./Footer.scss";

export const Footer = ({textAlign}) => {
    return (
        <div
            className="footer"
            align="center"
            style={{marginLeft: textAlign ? "-24px" : "-24px"}}
        >
            <div className="footer-div" style={{textAlign}}>
                Copyright 2022 - Ghirardelli Chocolate Company. In collaboration with{" "}
                <span className="secondary-color cursor-pointer fw-500">
          Unique Software Development.
        </span>
            </div>
        </div>
    );
};

