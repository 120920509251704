const envType = process.env.REACT_APP_ENV || "development";
const timeZone = JSON.parse(localStorage.getItem('userInfo'));

const types = {
  development: {
    BASE_URL: "http://localhost:3000",
    API_URL:
      "https://app-ghirardelliapi-dev-westus-001.azurewebsites.net/api/v1/",
    IMAGE_URL: "",
    TIMEZONE: global?.timeZoneName || timeZone?.timeZoneName,
  },
  staging: {},
  production: {
    BASE_URL: "http://localhost:3000/",
    API_URL: "https://api.gccpickandmix.com/api/v1/",
    IMAGE_URL: "",
    TIMEZONE: global?.timeZoneName || timeZone?.timeZoneName,
  },
};

export const config = types[envType];
